import styled from '@emotion/styled'

interface StyledNavColumnProps {
  background: string
}

export const StyledNav = styled.nav`
  position: fixed;
  left: 0;
  background: #fff;
  margin: 0;
  z-index: 99998;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  bottom: 50px;
  box-shadow: none;
  border-bottom: 1px solid #eaeaea;
  height: calc(100vh - 76px);
  width: 85%;
  min-height: calc(100vh - 76px);
  top: 0;
  bottom: 0;
  margin-top: 76px;
  overflow: hidden;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }

  > ul {
    padding: 0 17px;
  }

  .nav__item {
    width: calc(100% - 70px);
    font-weight: 500;
    font-size: 1rem;
    padding: 0;
    color: #212529;

    > div {
      > a {
        color: #222;
        display: block;
        text-decoration: none;
      }
    }
  }

  .toggle-menu {
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem;
    height: 100%;
    display: block;
    line-height: 1;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 50%;
      background: url('/images/gather.svg') no-repeat;
      background-size: 1000%;
      background-position: -30px -390px;
      position: absolute;
      right: 0;
      top: 25%;
    }
  }

  .dropdown-mega-menu {
    position: fixed;
    display: block;
    height: calc(100vh - 76px);
    top: 0;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #fff;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
    transition: all 0.3s;

    > li {
      position: relative;
    }

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }

  .nav__crowns {
    .dropdown-mega-menu {
      padding-bottom: 150px;
    }
  }

  .nav__content {
    .dropdown-mega-menu {
      > li {
        > .sub-menu {
          &:first-of-type {
            > li {
              &:first-of-type {
                flex: 0 0 100%;
                color: #212529;
                font-size: 1rem;
                font-weight: 600;
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-bottom: 1px #eae7e4 solid;
                max-width: initial;
                text-transform: uppercase;
                position: sticky;
                top: 0;
                background-color: white;
                z-index: 999;

                &:before {
                  content: '';
                  display: block;
                  width: 20px;
                  height: 100%;
                  background: url('/images/gather.svg') no-repeat;
                  background-size: 1000%;
                  background-position: -30px -381px;
                  position: absolute;
                  left: 5px;
                  top: 0;
                  transform: rotate(180deg);
                }
              }
            }
          }

          .list-menu {
            display: block;
            margin: 0 30px;
            width: 100%;
            padding-top: 20px;
            padding-bottom: 10px;
            border-bottom: 1px solid #eae7e4;
            ul {
              li {
                font-size: 14px;
                font-family: Karla;
                line-height: 20px;
                margin-bottom: 15px;
                > a {
                  text-decoration: none;
                  color: #615956;
                }
                &:first-of-type {
                  font-size: 18px;
                  font-family: SilkSerif;
                  color: #212529;
                  margin-bottom: 20px;
                  > a {
                    color: #212529;
                  }
                }
                &.bold {
                  font-size: 18px;
                  color: #212529;
                  border-top: 1px solid #eae7e4;
                  padding-top: 20px;
                  font-family: SilkSerif;
                  > a {
                    color: #212529;
                  }
                }
              }
            }
          }

          .image-menu {
            flex: 0 0 50%;
            img {
              width: 100px;
              height: 100px;
            }
          }

          .text-menu {
            flex: 0 0 100%;
            padding: 0 30px;
            font-size: 18px;
            color: #212529;
            line-height: 28px;
            .left {
              text-align: left;
              font-size: 18px;
              font-family: SilkSerif;
            }
          }

          .divider {
            width: 100%;
            height: 1px;
            background-color: #eae7e4;
            margin: 0 30px 20px;
          }
        }
      }
      .menu-link {
        text-decoration: none;
        margin-left: 20px;
        margin-bottom: 25px;
        color: #212529;
        font-size: 14px;
        letter-spacing: 0.2px;
        position: relative;
        display: inline-block;

        &::after {
          content: '';
          display: block;
          width: 20px;
          height: 100%;
          background: url('/images/gather_icon.svg') no-repeat;
          background-size: 700%;
          background-position: -24px -281px;
          position: absolute;
          right: -22px;
          top: 4px;
        }
      }
    }

    .sub-menu {
      display: flex;
      flex-wrap: wrap;

      > li {
        margin-bottom: 25px;
        position: relative;
        max-width: 100% !important;

        > a {
          display: block;
          padding: 0;
          font-size: 0.875rem;
          font-weight: 400;
          color: #222;
          text-decoration: none;
          text-align: center;
          position: relative;

          span {
            display: block;
            line-height: normal;
            font-size: 14px;
            color: #212529;
            letter-spacing: 0.2px;
          }
        }

        img {
          display: block;
          margin: 0 auto 10px;
        }

        &:first-of-type {
          flex: 0 0 100%;
          color: #212529;
          font-size: 1rem;
          font-weight: 600;
          text-align: center;
          border-bottom: 1px #eae7e4 solid;
          margin-bottom: 20px;
          max-width: initial;
          text-transform: uppercase;
        }
      }
    }
  }
`

export const StyledNavColumn = styled.li<StyledNavColumnProps>`
  border-top: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
  height: 80px;
  font-size: 0.875rem;
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    width: 60px;
    height: 60px;
    background-size: contain;
    margin-right: 10px;
    background-image: ${(props: any) => `url('${props.background}')`};
    background-repeat: no-repeat;
  }
`

export const StyledShade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 50px);
  min-height: calc(100vh - 76px);
  margin-top: 76px;
  background: rgba(0, 0, 0, 0.5);
  display: none;

  &.active {
    display: block;
  }
`

export const StyledCurrencyContainer = styled.div`
  width: 100%;
  height: 60px;
  border-top: 1px solid #d6d0cb;
  display: flex;
  align-items: center;
  padding: 0 17px;
  .currency {
    width: 55px;
  }
`
