import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { I18nConf } from '@config/i18n'

interface I18nProps {
  id: string
}

const I18n: FC<I18nProps> = ({ id, ...props }) => {
  return (
    <FormattedMessage id={id} defaultMessage={I18nConf.i18n[id]} {...props} />
  )
}

export default I18n
