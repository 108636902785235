import { createSlice, Slice } from '@reduxjs/toolkit'

import { getUserDetails } from './actions'

export const slice: Slice = createSlice({
  name: 'user',
  initialState: {
    isLogin: false,
    token: null,
    userDetail: null,
    selectedOptions: [],
    bundleItemsOptions: []
  },
  reducers: {
    setUserToken: (state: any, { payload }) => {
      state.isLogin = !!payload
      state.token = payload
    },
    setUserDetail: (state: any, { payload }) => {
      state.userDetail = payload
    },
    setInitialState: (state: any) => {
      state.isLogin = false
      state.token = null
      state.userDetail = null
    },
    setSelectedOptions: (state: any, { payload }) => {
      state.selectedOptions = payload
    },
    setBundleItemsOptions: (state: any, { payload }) => {
      state.bundleItemsOptions = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.fulfilled, (state, { payload }) => {
        state.userDetail = payload || null
      })
      .addCase(getUserDetails.rejected, (state) => {
        state.isLogin = false
        state.token = null
        state.userDetail = null
      })
  }
})
