import styled from '@emotion/styled'

export const StyledCopry = styled.div`
  span {
    display: inline-block;
    margin-right: 0.5rem;
    color: #615956;
    font-size: 0.75rem;
  }

  a {
    display: inline-block;
    margin: 0 0.5rem;
    color: #615956;
    font-size: 0.75rem;
  }
`
