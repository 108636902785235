import { useSearchBar } from '@hooks/SearchBar/useSearchBar'
import clsx from 'clsx'
import React from 'react'
import Autocomplete from './autocomplete'
import SearchField from './searchField'
import { StyledContainer, StyledForm, StyledRoot } from './styled'

interface SearchBarProps {
  isOpen: boolean
  setIsSearchOpen: (isOpen: boolean) => void
}

const SearchBar = React.forwardRef<HTMLDivElement, SearchBarProps>(
  (props: SearchBarProps, ref) => {
    const { isOpen, setIsSearchOpen } = props
    const talonProps = useSearchBar({ setIsSearchOpen, isOpen })
    const {
      containerRef,
      handleChange,
      handleFocus,
      handleSubmit,
      initialValues,
      isAutoCompleteOpen,
      setIsAutoCompleteOpen,
      valid,
      setSearchResult,
      searchValue
    } = talonProps

    return (
      <StyledRoot
        className={clsx({
          open: isOpen
        })}
        ref={ref}
      >
        <StyledContainer ref={containerRef}>
          <StyledForm autoComplete="off" initialValues={initialValues}>
            <div>
              <SearchField
                isSearchOpen={isOpen}
                onChange={handleChange}
                onFocus={handleFocus}
                onSubmit={handleSubmit}
              />
            </div>
            <div>
              <Autocomplete
                setVisible={setIsAutoCompleteOpen}
                valid={valid}
                setIsSearchOpen={setIsSearchOpen}
                visible={isAutoCompleteOpen}
                setSearchResult={setSearchResult}
                searchValue={searchValue}
              />
            </div>
          </StyledForm>
        </StyledContainer>
      </StyledRoot>
    )
  }
)

export default SearchBar
