import { ChevronDown, ChevronUp } from 'react-feather'

import {
  ClickAwayListener,
  Grow,
  Icon,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@headless/ui'
import { useCurrencyControll } from '@hooks/CurrencyControll'

import { StyledCurrency } from './styled'

function CurrencyControll() {
  const {
    available,
    anchorRef,
    current,
    open,
    handleClose,
    handleToggle,
    handleKeyDown,
    handleCurrencyChange
  } = useCurrencyControll()
  return (
    <StyledCurrency>
      <span className="currency" ref={anchorRef} onClick={handleToggle}>
        <span>{current}</span>
        <Icon src={open ? ChevronUp : ChevronDown} />
      </span>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} onKeyDown={handleKeyDown}>
                  {available.map((code: string) => {
                    return (
                      <MenuItem
                        key={`menu__${Math.random()}`}
                        onClick={() => {
                          handleCurrencyChange(code)
                        }}
                      >
                        {code}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </StyledCurrency>
  )
}

export default CurrencyControll
