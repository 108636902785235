export const I18nConf: any = {
  i18n: {
    'global.addToCart': 'Add To Cart',
    'global.adding': 'Adding...',
    'global.home': 'Home',
    'global.yes': 'Yes',
    'global.no': 'No',
    'global.clearAll': 'Clear All',
    'global.createAccount': 'Create an Account',
    'global.checkout': 'Proceed To Checkout',
    'global.login': 'Login',
    'global.required': 'This is a required filed.',
    'global.forgot': 'Forgot?',
    'global.goBack': 'Go Back',
    'global.save': 'Save',
    'global.submit': 'Submit',
    'global.send': 'Send',
    'global.signIn': 'Sign In',
    'global.signUp': 'Sign Up',
    'global.subscribe': 'Subscribe',
    'homepage.title': 'Home Page',
    'catalog.noFilter': 'There is no any filter.',
    'catalog.noResult': 'There is no any product.',
    'cart.title': 'Shopping Cart',
    'cart.bag': 'Shopping Bag',
    'checkout.title': 'Checkout',
    'trackOrder.checkout': 'Checkout Now',
    'forgot.title': 'Password Recovery',
    'login.title': 'Returning Customers',
    'resetPassword.title': 'Reset Your Password',
    'myAccount.title': 'My Account',
    'myOrders.title': 'My Orders',
    'myWishList.title': 'My Wish List',
    'myReviews.title': 'My Product Reviews',
    'myNewsletter.title': 'Newsletter Subscription',
    'myGdpr.title': 'GDPR',
    'myInformation.title': 'Account Information',
    'myAddress.title': 'Address Book',
    'myAddress.addAddress': 'Add New Address',
    'myAddress.editAddress': 'Edit Address',
    'myAddress.saveAddress': 'Save Address',
    'validate.email':
      'Please enter a valid email address (Ex: johndoe@domain.com).',
    'validate.password': 'Must contain at least 6 character(s).',
    'validate.passwordLenth':
      'Minimum of different classes of characters in password is {number}.',
    'success.title': 'Order # {id}'
  }
}
