import styled from '@emotion/styled'

import { Menu } from '@headless/ui'

export const StyledMenu = styled(Menu)({
  '& .MuiLink-root': {
    flex: 1,
    color: '#52575D',
    whiteSpace: 'nowrap',
    textDecoration: 'none'
  },
  '& .actions': {
    display: 'block',
    flexBasis: '100%'
  }
})

export const StyledEntry = styled.p`
  display: grid;
  margin-block-end: 0;
  grid-auto-flow: column;
  grid-column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
`

export const StyledDrawer = styled.div`
  padding: 0.9375rem;

  .MuiMenuItem-root {
    display: block;
    padding: 0.5rem 0;
  }

  .MuiLink-root {
    display: block;
    color: #222;
    font-size: 1.125rem;
    font-weight: 400;
    text-decoration: none;

    &:hover {
      color: #dbb19f;
    }
  }

  .MuiButton-containedPrimary {
    position: fixed;
    right: 0.9375rem;
    bottom: 30vh;
    left: 0.9375rem;
    width: calc(100% - 1.875rem);
    padding: 0.75rem 1.25rem;
    color: #222;
    font-size: 1rem;
    z-index: 30;
    text-transform: capitalize;
    border-radius: 0;
    background-color: #f2e0d6;

    &.bottom {
      bottom: 22vh;
    }
  }
`
