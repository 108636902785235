import { createSlice, Slice } from '@reduxjs/toolkit'

import {
  setBillingAddress,
  setCheckoutBilling,
  setGuestEmail,
  setPaymentMethod,
  setShippingAddress,
  setShippingMethod
} from './actions'

export const slice: Slice = createSlice({
  name: 'checkout',
  initialState: {
    stepIndex: 0,
    customerEmail: null,
    billingAddress: null,
    shippingAddress: null,
    billingId: null,
    shippingId: null,
    applyShipping: null,
    applyBilling: null,
    shippingMethods: [],
    paymentMethods: [],
    chooseShippingMethod: null,
    choosePaymentMethod: null,
    checkoutAddress: [],
    checkoutComment: '',
    sameAsShipping: true,
    shouldShippingMethodAutoFinished: false // checkout 用于 stepindex
  },
  reducers: {
    setCheckoutState: (state: any, { payload }) => {
      const { available_payment_methods, email, shipping_addresses } = payload
      if (shipping_addresses.length > 0) {
        const {
          available_shipping_methods,
          selected_shipping_method,
          ...rest
        } = shipping_addresses[0]
        state.customerEmail = email
        state.shippingAddress = rest
        state.shippingMethods = available_shipping_methods || []
        state.paymentMethods = available_payment_methods || []
        state.chooseShippingMethod = selected_shipping_method || null
      }
    },
    setStepIndex: (state: any, { payload }) => {
      state.stepIndex = payload
    },
    setShouldShippingMethodAutoFinished: (state: any, { payload }) => {
      state.shouldShippingMethodAutoFinished = payload
    },
    setShippingId: (state: any, { payload }) => {
      state.shippingId = payload
    },
    setBillingId: (state: any, { payload }) => {
      state.billingId = payload
    },
    setShippingMethods: (state: any, { payload }) => {
      state.shippingMethods = payload
    },
    setPaymentMethod: (state: any, { payload }) => {
      state.choosePaymentMethod = payload
    },
    setCheckoutAddress: (state: any, { payload }) => {
      state.checkoutAddress = [...payload]
    },
    setApplyShipping: (state: any, { payload }) => {
      state.applyShipping = payload
    },
    setApplyBilling: (state: any, { payload }) => {
      state.applyBilling = payload
    },
    setCheckoutComment: (state: any, { payload }) => {
      state.checkoutComment = payload
    },
    setSameAsShipping: (state: any, { payload }) => {
      state.sameAsShipping = payload
    },
    setInitialState: (state: any) => {
      state.stepIndex = 0
      state.customerEmail = null
      state.billingAddress = null
      state.shippingAddress = null
      state.billingId = null
      state.shippingId = null
      state.applyShipping = null
      state.applyBilling = null
      state.shippingMethods = []
      state.paymentMethods = []
      state.chooseShippingMethod = null
      state.choosePaymentMethod = null
      state.checkoutAddress = []
      state.checkoutComment = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setGuestEmail.fulfilled, (state, { payload }) => {
        if (payload) {
          state.customerEmail = payload.email
        }
      })
      .addCase(setShippingAddress.fulfilled, (state, { payload }) => {
        if (payload) {
          const { available_payment_methods, shipping_addresses } = payload
          const {
            available_shipping_methods,
            selected_shipping_method,
            ...rest
          } = shipping_addresses[0]
          state.shippingAddress = rest
          state.shippingMethods = available_shipping_methods || []
          state.paymentMethods = available_payment_methods || []
        }
      })
      .addCase(setBillingAddress.fulfilled, (state, { payload }) => {
        if (payload) {
          const { billing_address } = payload
          state.billingAddress = billing_address
        }
      })
      .addCase(setCheckoutBilling.fulfilled, (state, { payload }) => {
        if (payload) {
          const { billing_address } = payload
          state.billingAddress = billing_address
        }
      })
      .addCase(setShippingMethod.fulfilled, (state, { payload }) => {
        if (payload) {
          const { available_payment_methods, shipping_addresses } = payload
          const { selected_shipping_method } = shipping_addresses[0]
          state.chooseShippingMethod = selected_shipping_method
          state.paymentMethods = available_payment_methods || []
          state.stepIndex = 2
        }
      })
      .addCase(setPaymentMethod.fulfilled, (state, { payload }) => {
        if (payload) {
          const { selected_payment_method } = payload
          state.choosePaymentMethod = selected_payment_method
        }
      })
  }
})
