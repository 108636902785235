import { createSlice, Slice } from '@reduxjs/toolkit'

export const slice: Slice = createSlice({
  name: 'app',
  initialState: {
    currency: null,
    isMobile: false,
    pageLoading: false,
    storeConfig: null
  },
  reducers: {
    setAppConfig: (state: any, { payload }) => {
      const { currency, storeConfig, isMobile } = payload
      state.currency = currency
      state.storeConfig = storeConfig
      state.isMobile = isMobile
    },
    setPageLoading: (state: any, { payload }) => {
      state.pageLoading = payload
    }
  }
})
