import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'

import { GET_GUEST_CART } from '@graphql/queries/getGuestCart'
import { GET_CUSTOMER_CART } from '@graphql/queries/getCustomerCart'
import { CREATE_CART_MUTATION } from '@graphql/mutations/createEmptyCart'
import { REMOVE_CART_MUTATION } from '@graphql/mutations/removeItemFromCart'
import { useAwaitQuery } from '@headless/hooks'
import { findVariant } from '@headless/utils'
import { asyncActions as cartAsyncActions } from '@store/cart'

export const useMiniCartList = () => {
  const dispatch = useDispatch()
  const fetchGuestCart = useAwaitQuery(GET_GUEST_CART)
  const fetchCustomerCart = useAwaitQuery(GET_CUSTOMER_CART)
  const [fetchCartId] = useMutation(CREATE_CART_MUTATION)
  const [removeCartMutation] = useMutation(REMOVE_CART_MUTATION)
  const storeConfig = useSelector((state: any) => state.app.storeConfig)
  const [open, setOpen] = useState<boolean>(false)
  const [id, setId] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const suffix: string = useMemo(() => {
    return storeConfig?.product_url_suffix ?? ''
  }, [storeConfig])

  const handleOpen = (number: any) => {
    setId(number)
    setOpen(true)
  }

  const handleClose = () => {
    setId(0)
    setOpen(false)
  }

  const handleFetchImage = (options: any[], product: any) => {
    let image: any = product.thumbnail
    const variants: any[] = product?.variants ?? []

    if (options.length > 0) {
      const selections: any = {}

      options.forEach((option: any) => {
        variants.forEach((variant: any) => {
          const match: any = variant.attributes.find(
            (attr: any) => attr.uid === option.value_id
          )
          if (match) selections[match.code] = option.value_id
        })
      })

      const result: any = findVariant({ variants, selections })
      image = result.product.thumbnail
    }

    return image
  }

  const handleConfirm = useCallback(async () => {
    if (id) {
      try {
        setLoading(true)
        await dispatch(
          cartAsyncActions.removeCartItem({
            fetchGuestCart,
            fetchCustomerCart,
            fetchCartId,
            removeCartMutation,
            id
          })
        )
        setLoading(false)
        setOpen(false)
      } catch (error) {
        setLoading(false)
      }
    }
  }, [
    dispatch,
    fetchCartId,
    fetchCustomerCart,
    fetchGuestCart,
    id,
    removeCartMutation
  ])

  return {
    open,
    loading,
    suffix,
    handleOpen,
    handleClose,
    handleConfirm,
    handleFetchImage
  }
}
