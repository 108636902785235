import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import { toReactIntl } from '@headless/utils'

const LocaleContextProvider = ({ appState, i18nState, ...props }: any) => {
  const locale = appState?.storeConfig?.locale || 'en_US'
  const messages = i18nState.languages ?? {}
  const language = toReactIntl(locale)

  const onIntlError = (error: any) => {
    if (messages) {
      if (error.code === 'MISSING_TRANSLATION') {
        console.warn('Missing translation', error.message)
        return
      }
      throw error
    }
  }

  return (
    <IntlProvider
      key={language}
      defaultLocale={toReactIntl(locale)}
      locale={language}
      messages={messages}
      {...props}
      onError={onIntlError}
    />
  )
}

const mapStateToProps = ({ app, i18n }: any) => ({
  appState: app,
  i18nState: i18n
})

export default connect(mapStateToProps)(LocaleContextProvider)
