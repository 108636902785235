import styled from '@emotion/styled'

export const StyledPrice = styled.span``

export const CrossedPrice = styled.span`
  text-decoration: line-through;
  font-size: 1.25rem;
  font-weight: 500;
  color: #999 !important;
  margin-left: 5px;
`
