import { useEffect, useState } from 'react'

import { GET_CMS_BLOCK } from '@graphql/queries/getCmsBlock'
import { useAwaitQuery } from '@headless/hooks'

type Block = {
  content: string
  identifiers: string
  title: string
}

export const useCmsBlock = (identifiers: string) => {
  const getCmsBlock: any = useAwaitQuery(GET_CMS_BLOCK)
  const [block, setBlock] = useState<Block | null>(null)
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    const fetchCmsBlock = async () => {
      try {
        const { data } = await getCmsBlock({
          variables: {
            identifiers: [identifiers]
          }
        })

        const result: any = data?.cmsBlocks?.items?.[0] ?? null
        setBlock(result)
        setTimeout(() => {
          setVisible(true)
        }, 500)
      } catch (error) {
        console.error(error)
      }
    }

    fetchCmsBlock()
  }, [getCmsBlock, identifiers])

  useEffect(() => {
    const columnEle: any = document.querySelectorAll('.footer__column > h4')

    if (visible && columnEle) {
      for (let i = 0; i < columnEle.length; i++) {
        columnEle[i].onclick = () => {}
        columnEle[i].onclick = () => {
          if (columnEle[i].parentNode.classList.contains('active')) {
            columnEle[i].parentNode.classList.remove('active')
          } else {
            columnEle[i].parentNode.classList.add('active')
          }
        }
      }
    }
  }, [visible])

  return {
    block,
    visible
  }
}
