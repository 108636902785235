import { Icon } from '@headless/ui'
import React from 'react'
import { Search } from 'react-feather'
import { StyledSearch } from './styled'

const QuickSearch = React.forwardRef(
  (props: { onClick?: () => void; hideNav: () => void }, ref: any) => {
    const { onClick, hideNav } = props

    return (
      <StyledSearch ref={ref}>
        <Icon
          src={Search}
          onClick={() => {
            hideNav()
            if (onClick) {
              onClick()
            }
          }}
        />
      </StyledSearch>
    )
  }
)

export default QuickSearch
