import { gql, DocumentNode } from '@apollo/client'

export const GET_CMS_CATEGORIES: DocumentNode = gql`
  query getCmsCategories($code: String) {
    CmsCategories(code: $code) {
      content
      id
      title
    }
  }
`
