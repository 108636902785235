import styled from '@emotion/styled'
import { Form } from 'informed'

export const StyledRoot = styled.div`
  width: 100%;
  display: none;
  &.open {
    display: block;
  }
  @media screen and (max-width: 767px) {
    background-color: #f8f8f8;
    height: calc(100vh - 80px);
    overflow-y: auto;
  }
`

export const StyledContainer = styled.div``

export const StyledForm = styled(Form)({
  '&': {
    display: 'flex',
    flexDirection: 'column'
  }
})

export const StyledInputContainer = styled.div`
  position: relative;
  @media screen and (max-width: 767px) {
    padding: 0 16px;
    margin-top: 24px;
  }
`

export const StyledInput = styled.input`
  width: 100%;
  height: 80px;
  line-height: 80px;
  border: none;
  outline: none;
  border-top: 1px solid #e7ded5;
  border-bottom: 1px solid #e7ded5;
  padding: 0 60px;
  background: url(/images/search.svg) no-repeat;
  background-position: center right 60px;

  @media screen and (max-width: 767px) {
    height: 50px;
    line-height: 50px;
    border-radius: 25px;
    padding-left: 20px;
    padding-right: 38px;
    border: 1px solid #e7ded5;
    background-image: none;
  }
`

export const StyledArrow = styled.span`
  display: none;
  position: absolute;
  background: url(/images/arrow-right.svg) center no-repeat;
  width: 36px;
  height: 100%;
  top: 50%;
  right: 36px;
  transform: translate(0, -50%);
  @media screen and (max-width: 767px) {
    display: block;
  }
`

export const StyledAutoCompleteContainer = styled.div`
  display: flex;
  padding: 40px 60px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 0 16px;
  }
`

export const StyledTrending = styled.div`
  flex: 0 0 391px;
  border-right: 1px solid #d6d0cb;
  @media screen and (max-width: 767px) {
    flex: auto;
    border-right: none;
    margin-top: 34px;
  }
`

export const StyledAutoCompleteTitle = styled.p`
  font-size: 18px;
  line-height: 28px;
  color: #212529;
  font-family: SilkSerif;
  margin-bottom: 15px;
`
export const StyledTrendingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  padding-right: 48px;
  @media screen and (max-width: 767px) {
    padding-right: 0;
  }
  a {
    color: #615956;
    font-family: Karla;
    font-size: 14px;
    line-height: 38px;
    border: 1px solid #d6d0cb;
    padding: 0 60px 0 42px;
    white-space: nowrap;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-shrink: 0;
    :hover {
      border-color: #212529;
    }
    > div {
      flex-shrink: 0;
    }
    > span {
      padding-left: 20px;
    }
    &:nth-of-type(1) {
      /* background: url('/images/colors/silver.svg') 20px center no-repeat; */
      .img {
        background: #f7eed0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    &:nth-of-type(2) {
      /* background: url('/images/colors/gold.svg') 20px center no-repeat; */
      .img {
        background: #ca9927;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    &:nth-of-type(3) {
      /* background: url('/images/colors/purple.svg') 20px center no-repeat; */

      .img {
        background: #cb1e30;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    &:nth-of-type(4) {
      /* background: url('/images/colors/blue.svg') 20px center no-repeat; */

      .img {
        background: #f58381;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    &:nth-of-type(5) {
      /* background: url('/images/colors/black.svg') 20px center no-repeat; */

      .img {
        background: #8cbcd4;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    &:nth-of-type(6) {
      /* background: url('/images/colors/pearl.svg') 20px center no-repeat; */

      .img {
        background: #f1e79c;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
  }
`

export const StyledNewProducts = styled.div`
  flex: 0 0 560px;
  padding: 0 48px;
  border-right: 1px solid #d6d0cb;
`

export const StyledNewProductsContaienr = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 10px;
  a {
    font-family: karla;
    font-size: 16px;
    color: #615956;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

export const StyledSearchResult = styled.div`
  flex: 1;
  padding-left: 48px;
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    padding-left: 0;
    margin-top: 35px;
  }
`

export const StyledSeeAll = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const StyledProductList = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 10px;
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`

export const StyledProductLink = styled.a`
  cursor: pointer;
  &:hover {
    color: #615956;
    p {
      text-decoration: underline;
      color: #212529;
    }
  }
  p {
    font-size: 14px;
    font-family: Karla;
    color: #615956;
  }
`

export const StyledSearchInfo = styled.p`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 767px) {
    min-height: 150px;
  }
`
