import useMediaQuery from '@mui/material/useMediaQuery'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export const useMiniCart = () => {
  const router = useRouter()
  const cartDetail = useSelector((state: any) => state.cart.cartDetail)
  const showMiniCart = useSelector((state: any) => state.cart.showMiniCart)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isMobile: boolean = useMediaQuery('(max-width:767px)')

  useEffect(() => {
    console.log('🍁showMiniCart', showMiniCart)
    if (showMiniCart) {
      const dom: HTMLButtonElement | null = document.querySelector(
        '.MuiBadge-root.BaseBadge-root'
      )
      console.log('🍩dom', dom)
      setAnchorEl(dom)
    } else {
      setAnchorEl(null)
    }
  }, [showMiniCart])

  const cartQty: number = useMemo(() => {
    return cartDetail?.total_quantity ?? 0
  }, [cartDetail])

  const cartList = useMemo(() => {
    return cartDetail?.items ?? []
  }, [cartDetail])

  const subTotal = useMemo(() => {
    return cartDetail?.prices?.grand_total?.value ?? 0
  }, [cartDetail])

  const handleClick = (e: any) => {
    if (isMobile && cartQty === 0) {
      router.push('/checkout/cart')
      return false
    }

    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleTrueClick = () => {
    router.push('/checkout/cart')
  }

  return {
    anchorEl,
    cartQty,
    cartList,
    isMobile,
    subTotal,
    handleClick,
    handleClose,
    handleTrueClick
  }
}
