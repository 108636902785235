import { destroyCookie, parseCookies } from 'nookies'

import { GET_STORE_CONFIG } from '@graphql/queries/getStoreConfig'
import { isMobile } from '@headless/utils'
import { actions as appActions } from '@store/app'
import { actions as i18nActions } from '@store/i18n'
import { actions as userActions } from '@store/user'

interface AppEntry {
  apollo: any
  req: any
  res: any
  reduxStore: any
}

export const fetchApp = async (ctx: AppEntry) => {
  const { apollo, reduxStore, req, res } = ctx
  const { dispatch } = reduxStore
  const cookies = parseCookies({ req })
  const agent: string = req?.headers?.['user-agent'] ?? ''
  const token: string = cookies?.access_token ?? ''

  await apollo
    .query({
      query: GET_STORE_CONFIG,
      context: {
        headers: {
          Store: 'gn_us'
        }
      }
    })
    .then(async (response: any) => {
      if (response.data) {
        const i18nJson = await import(
          `../../public/i18n/${response.data.storeConfig.locale}.json`
        )

        await dispatch(
          appActions.setAppConfig({
            ...response.data,
            isMobile: isMobile(agent)
          })
        )
        await dispatch(i18nActions.setLanguages(i18nJson.default))
        await dispatch(userActions.setUserToken(token))
      }
    })
    .catch((error: any) => {
      const apiResult = error.networkError.result
      const apiMessage = apiResult?.errors[0].message ?? ''

      if (apiMessage.indexOf('Requested store is not found') > -1) {
        destroyCookie(ctx, 'access_token')
        destroyCookie(ctx, 'cart_id')
        destroyCookie(ctx, 'store_code')
        destroyCookie(ctx, 'currency_code')
        res.writeHead(302, { Location: '/' })
        res.end()
      }
    })
}
