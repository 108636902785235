import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

const AppHelmet = () => {
  const storeConfig = useSelector((state: any) => state.app.storeConfig)

  const locale = useMemo(() => {
    const lang = storeConfig?.locale || ''
    return lang.replace(/\_/g, '-')
  }, [storeConfig])

  // gn 的 icon 直接从 public 目录拿算了
  const shortcut = '/favicon.ico'

  return (
    <Helmet htmlAttributes={{ lang: locale }}>
      <link rel="preload" as="image" href={shortcut} />
      <link rel="icon" type="image/png" sizes="16x16" href={shortcut} />
      <link rel="shortcut icon" type="image/x-icon" href={shortcut} />
    </Helmet>
  )
}

export default AppHelmet
