import {
  useEffect,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
  SyntheticEvent
} from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import { cookie } from '@headless/utils'

export const useCurrencyControll = () => {
  const router = useRouter()
  const anchorRef = useRef<HTMLElement>(null)
  const currency = useSelector((state: any) => state.app.currency)
  const [open, setOpen] = useState<boolean>(false)
  const [current, setCurrent] = useState<string>('')

  const available = useMemo(() => {
    const result: string[] = currency?.available ?? []
    const match: string[] = result.filter((item: string) => item !== current)
    return match
  }, [currency, current])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const handleCurrencyChange = (code: string) => {
    cookie.setItem('currency_code', code.toLocaleUpperCase())
    setOpen(false)
    router.reload()
  }

  useEffect(() => {
    const code: string = currency?.code ?? ''
    const cache: string = cookie.getItem('currency_code') || ''
    setCurrent(cache === code ? code : cache || code)
  }, [currency])

  return {
    available,
    anchorRef,
    current,
    open,
    handleClose,
    handleToggle,
    handleKeyDown,
    handleCurrencyChange
  }
}
