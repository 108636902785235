import { LazyScroll } from '@headless/ui'
import { useFooter } from '@hooks/Footer'

import CopyRight from '@components/CopyRight'
import Links from './Links'
import { StyledFooter, StyledCopyRight } from './styled'

const Footer = () => {
  const { isCheckout } = useFooter()

  return (
    <StyledFooter isCheckout={isCheckout}>
      {!isCheckout && (
        <LazyScroll>
          <Links />
        </LazyScroll>
      )}
      <StyledCopyRight isCheckout={isCheckout}>
        <CopyRight />
        <picture>
          <img
            src="/images/payment.svg"
            alt="DMCA.com Protection Status"
            width="480px"
          />
          <img src="/images/dmca.png" alt="DMCA.com Protection Status" />
        </picture>
      </StyledCopyRight>
    </StyledFooter>
  )
}

export default Footer
