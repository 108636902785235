import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import useMediaQuery from '@mui/material/useMediaQuery'

import { GET_CUSTOMER_CART } from '@graphql/queries/getCustomerCart'
import { GET_GUEST_CART } from '@graphql/queries/getGuestCart'
import { CREATE_CART_MUTATION } from '@graphql/mutations/createEmptyCart'
import { REVOKE_TOKEN_MUTATION } from '@graphql/mutations/revokeCustomerToken'
import { useAwaitQuery } from '@headless/hooks'
import { cookie } from '@headless/utils'
import {
  actions as cartActions,
  asyncActions as cartAsyncActions
} from '@store/cart'
import { actions as checkoutActions } from '@store/checkout'
import { actions as userActions } from '@store/user'

export const useAccountEntry = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const [fetchCartId] = useMutation(CREATE_CART_MUTATION)
  const fetchGuestCart = useAwaitQuery(GET_GUEST_CART)
  const fetchCustomerCart = useAwaitQuery(GET_CUSTOMER_CART)
  const [logoutMutation] = useMutation(REVOKE_TOKEN_MUTATION)
  const isLogin: boolean = useSelector((state: any) => state.user.isLogin)
  const userDetail: any = useSelector((state: any) => state.user.userDetail)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMobile: boolean = useMediaQuery('(max-width:767px)')

  const menuLinks = useMemo(() => {
    return isLogin
      ? [
          {
            url: '/customer',
            title: 'My Account'
          },
          {
            url: '/customer/orders',
            title: 'My Orders'
          },
          {
            url: '/customer/wishlist',
            title: 'My Wish List'
          },
          {
            url: '/customer/address',
            title: 'Address Book'
          },
          {
            url: '/customer/reviews',
            title: 'My Product Reviews'
          },
          {
            url: '/customer/newsletter',
            title: 'Newsletter Subscriptions'
          },
          {
            url: '/customer/stripe/cards',
            title: 'My Saved Cards'
          },
          {
            url: '/customer/gdpr',
            title: 'GDPR'
          },
          {
            url: '#',
            title: 'Sign Out'
          }
        ]
      : [
          {
            url: '/account/login',
            title: 'Sign In'
          },
          {
            url: '/account/create',
            title: 'Create an Account'
          }
        ]
  }, [isLogin])

  const customerName = useMemo(() => {
    return userDetail?.firstname ?? null
  }, [userDetail])

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCreateEmptyCart = useCallback(async () => {
    await dispatch(
      cartAsyncActions.getCartDetails({
        fetchCartId,
        fetchGuestCart,
        fetchCustomerCart
      })
    )
  }, [dispatch, fetchCartId, fetchCustomerCart, fetchGuestCart])

  const handleLogout = useCallback(async () => {
    try {
      const { data } = await logoutMutation()
      const result: boolean = data?.revoke?.result ?? false

      if (result) {
        await dispatch(cartActions.setInitialState({}))
        await dispatch(checkoutActions.setInitialState({}))

        await dispatch(userActions.setInitialState({}))
        await cookie.removeItem('access_token')
        await cookie.removeItem('cart_id')
        await handleCreateEmptyCart()
        await router.push('/account/login')
      }
    } catch (error) {
      return null
    }
  }, [dispatch, handleCreateEmptyCart, logoutMutation, router])

  return {
    anchorEl,
    customerName,
    menuLinks,
    isMobile,
    isLogin,
    handleClick,
    handleClose,
    handleLogout
  }
}
