import styled from '@emotion/styled'

export const StyledCartModal = styled.div`
  width: 48rem;
  padding: 1.25rem;

  .MuiDialogTitle-root {
    padding: 0 0 0.625rem;
    font-size: 1rem;
    font-weight: 600;
    border-bottom: 1px solid #eee;
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  .MuiDialogActions-root {
    padding: 1.25rem 0 0;
    border-top: 1px solid #eee;

    .MuiButtonBase-root {
      width: 15.3125rem;
      padding: 0.9375rem;
      color: #000;
      font-size: 1rem;
      font-weight: 500;
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 0;

      &:hover {
        background-color: #f2e0d6;
        border-color: #f2e0d6;
      }
    }

    .MuiButton-containedPrimary {
      margin-left: 1.25rem;
      background-color: #f2e0d6;
      border-color: #f2e0d6;

      &:hover {
        background-color: #fff;
        border-color: #000;
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 1rem;

    .MuiDialogActions-root {
      .MuiButtonBase-root {
        padding: 0.9375rem 0;
        font-size: 0.75rem;
      }

      .MuiButton-containedPrimary {
        margin-left: 1rem;
      }
    }
  }
`

export const StyledCartContent = styled.div`
  display: grid;
  padding: 1.25rem 0.625rem;
  grid-template-columns: 6.25rem 1fr;
  grid-column-gap: 1.25rem;

  .cartmodal {
    &__content {
      > p {
        font-size: 1rem;
        margin-bottom: 0.1rem;
      }

      .name {
        margin-bottom: 0.625rem;
        color: #222;
        font-size: 1rem;
        font-weight: 500;
        text-transform: capitalize;
      }

      .title {
        color: #969696;
        font-weight: 500;
        margin-right: 0.625rem;
      }
    }
  }
`
