import { useEffect, useRef } from 'react'

import { StyledScrollTop } from './styled'

const BackTop = () => {
  const scrollRef = useRef(null)

  const handleScrollTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0
    })
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop: number =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop
      const target: any = scrollRef.current

      if (scrollTop > 20) {
        target.style.bottom = '85px'
      } else {
        target.style.bottom = '-45px'
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <StyledScrollTop
      ref={scrollRef}
      style={{ bottom: '-45px' }}
      onClick={handleScrollTop}
    >
      <i className="icon icon-backtop" />
    </StyledScrollTop>
  )
}

export default BackTop
