import { KeyboardEvent, useCallback } from 'react'
import { StyledArrow, StyledInput, StyledInputContainer } from './styled'

const SearchField = (props: any) => {
  const { onChange, onFocus, onSubmit } = props

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onSubmit()
      }
    },
    [onSubmit]
  )

  return (
    <StyledInputContainer>
      <StyledInput
        id="search_query"
        data-cy="SearchField-textInput"
        placeholder="Find What You're Looking For"
        autoFocus
        onFocus={onFocus}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <StyledArrow onClick={onSubmit} />
    </StyledInputContainer>
  )
}

export default SearchField
