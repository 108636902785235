import { Menu, X } from 'react-feather'

import { Icon } from '@headless/ui'
import { useHeader } from '@hooks/Header'

import AccountEntry from '@components/AccountEntry'
import AnnouncementsList from '@components/AnnouncementsList/'
import CmsNavigation from '@components/CmsNavigation'
import CurrencyControll from '@components/CurrencyControll'
import Logo from '@components/Logo'
import MiniCart from '@components/MiniCart'
import Navigation from '@components/Navigation'
import QuickSearch from '@components/QuickSearch'
import SearchBar from '@components/SearchBar'
import { Suspense } from 'react'
import {
  StyledContainer,
  StyledHeader,
  StyledLogo,
  StyledMiddle,
  StyledTools,
  StyledWrapper
} from './styled'

const Header = () => {
  const {
    // formKey,
    isCustomized,
    isCheckout,
    isShowMenu,
    isShowCurrency,
    isVisible,
    setIsVisible,
    handleToggle,
    hideNav,
    handleSearchTriggerClick,
    isSearchOpen,
    setIsSearchOpen,
    searchRef,
    searchTriggerRef
  } = useHeader()

  const onMobileClickOutside = () => {
    handleToggle()
    setIsVisible(false)
  }

  const searchBar = isSearchOpen ? (
    <Suspense fallback={null}>
      <SearchBar
        isOpen={isSearchOpen}
        setIsSearchOpen={setIsSearchOpen}
        ref={searchRef}
      />
    </Suspense>
  ) : null

  return (
    <>
      <StyledHeader>
        <StyledContainer>
          {!isCheckout && <AnnouncementsList />}
          <StyledWrapper className="headless__area" isCheckout={isCheckout}>
            {!isCheckout && (
              <StyledMiddle>
                {isShowMenu && (
                  <Icon src={isVisible ? X : Menu} onClick={handleToggle} />
                )}
                {isShowCurrency ? <CurrencyControll /> : null}
                <QuickSearch
                  onClick={handleSearchTriggerClick}
                  hideNav={hideNav}
                  ref={searchTriggerRef}
                />
              </StyledMiddle>
            )}
            <StyledLogo onClick={hideNav}>
              <Logo />
            </StyledLogo>
            {!isCheckout && (
              <StyledTools onClick={hideNav}>
                <AccountEntry />
                <MiniCart />
              </StyledTools>
            )}
          </StyledWrapper>
          {!isCheckout && (
            <>
              {isCustomized ? (
                <CmsNavigation onMobileClickOutside={onMobileClickOutside} />
              ) : (
                <Navigation />
              )}
            </>
          )}
        </StyledContainer>
        <div className="quick__search" />
        {searchBar}
      </StyledHeader>
    </>
  )
}

export default Header
