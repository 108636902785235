import styled from '@emotion/styled'

export const StyledSubscribe = styled.div`
  margin-top: 1rem;

  > p {
    color: #222;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .success {
    color: #212529;
    margin-bottom: 0;
    text-transform: unset;
  }
`

export const StyledTextField = styled.div`
  .MuiInputBase-root {
    display: grid;
    padding-right: 0;
    background-color: #f9f0eb;
    border-radius: unset;
    grid-template-columns: 1fr 9.375rem;

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23);
      }
    }
  }

  .MuiInputBase-input {
    height: 0.75rem;
    color: #999;
    font-size: 1rem;
  }

  .MuiButtonBase-root {
    width: 9.375rem;
    padding: 0.5rem 1rem;
    color: #f2e0d6;
    font-size: 1rem;
    background-color: #212529;
    border-radius: unset;
  }

  .MuiButtonBase-root {
    &:hover {
      background-color: #212529;
    }
  }
`
