import { useCallback, useMemo } from 'react'

/**
 * Return props necessary to render a SuggestedProduct component.
 *
 * @param {Object} props
 * @param {Object} props.price_range - price range
 * @param {String} props.url_key - url key
 * @param {String} props.url_suffix - url suffix
 * @param {String} props.sku - product sky
 * @param {Function} props.onNavigate - callback to fire on link click
 */
export const useSuggestedProduct = (props: any) => {
  const { price, price_range, onNavigate } = props

  const handleClick = useCallback(() => {
    // TODO:
    if (typeof onNavigate === 'function') {
      onNavigate()
    }
  }, [onNavigate])

  // fall back to deprecated field if price range is unavailable
  const priceProps = useMemo(() => {
    return {
      currencyCode:
        price_range?.maximum_price?.final_price?.currency ||
        price.regularPrice.amount.currency,
      value:
        price_range?.maximum_price?.final_price?.value ||
        price.regularPrice.amount.value
    }
  }, [
    price.regularPrice.amount.currency,
    price.regularPrice.amount.value,
    price_range?.maximum_price?.final_price?.currency,
    price_range?.maximum_price?.final_price?.value
  ])

  // const uri = useMemo(
  //   () => return `/${url_key}${url_suffix || '',
  //   [url_key, url_suffix]
  // )
  // TODO:
  const uri = null

  return {
    priceProps,
    handleClick,
    uri
  }
}
