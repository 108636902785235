import { gql } from '@apollo/client'

export const GET_CMS_ANNOUNCEMENT = gql`
  query getCmsAnnouncements {
    CmsAnnouncements {
      id
      title
      content
      link
      position
    }
  }
`
