import { clsx } from 'clsx'
import { ShoppingBag } from 'react-feather'

import { Badge, Icon, Link, Popover } from '@headless/ui'
import { useMiniCart } from '@hooks/MiniCart'

import BasePrice from '@components/BasePrice'
import MiniCartList from './MiniCartList'
import {
  StyledCartAction,
  StyledCartPop,
  StyledEmpty,
  StyledMiniCart,
  StyledTotal
} from './styled'

const MiniCart = () => {
  const {
    anchorEl,
    cartQty,
    cartList,
    isMobile,
    subTotal,
    handleClick,
    handleClose,
    handleTrueClick
  } = useMiniCart()

  const MiniCartOver = ({ className, children }: any) => {
    return (
      <StyledMiniCart className={className} onMouseLeave={handleClose}>
        {cartQty > 0 ? (
          <div>
            <h3 className="minicart__title">
              <span>Shopping Bag</span>
            </h3>
            <MiniCartList cartList={cartList} />
            <StyledTotal>
              <span className="label">Cart Subtotal:</span>
              <BasePrice value={subTotal} />
            </StyledTotal>
            <StyledCartAction>
              <Link
                className="checkout"
                href="/checkout"
                title="Go To Checkout"
                onClick={handleClose}
              >
                <span>Go To Chekcout</span>
              </Link>
              <Link
                href="/checkout/cart"
                title="View Cart"
                onClick={handleClose}
              >
                <span>View Cart</span>
              </Link>
            </StyledCartAction>
          </div>
        ) : (
          <StyledEmpty className="empty">
            You have no items in your shopping cart.
          </StyledEmpty>
        )}
        {children}
      </StyledMiniCart>
    )
  }

  return (
    <StyledCartPop>
      <Badge
        className={clsx({
          hidden: cartQty === 0
        })}
        badgeContent={cartQty}
        showZero
        color="success"
        onClick={handleTrueClick}
        onMouseEnter={handleClick}
      >
        <Icon src={ShoppingBag} />
      </Badge>
      {isMobile ? null : (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          onClose={handleClose}
        >
          <MiniCartOver />
        </Popover>
      )}
    </StyledCartPop>
  )
}

export default MiniCart
