import { useMemo } from 'react'
import { useSelector } from 'react-redux'

interface BasePriceProps {
  decimal: number
  delimiter: string
  digit: number
  round: boolean
  value: number
}

export const useBasePrice = ({
  decimal,
  delimiter,
  digit,
  round,
  value
}: BasePriceProps) => {
  const currency = useSelector((state: any) => state.app.currency)

  const symbol: string = useMemo(() => {
    return currency?.symbol ?? ''
  }, [currency])

  const price = useMemo(() => {
    const minusSign = value < 0 ? '-' : ''
    let money = String(Number(Math.abs(value)).toFixed(decimal))

    if (!round) {
      const param = String(
        Math.floor(parseFloat(String(value)) * 10 ** decimal)
      )
      const leftMoney = param.substring(0, param.length - decimal)
      const rightDecimal = param.substr(param.length - decimal)
      money = `${leftMoney}.${rightDecimal}`
    }

    const left = money.split('.')[0]
    const right = money.split('.')[1]

    const regexp = new RegExp(`(\\d)(?=(\\d{${digit}})+$)`, 'gi')
    const result = left.replace(regexp, `$1${delimiter}`)
    const priceValue = `${minusSign}${symbol}${result}`

    if (!right && typeof right === 'undefined') {
      return priceValue
    }

    return `${priceValue}.${right}`
  }, [decimal, delimiter, digit, round, symbol, value])

  return {
    price
  }
}
