import { ApolloProvider } from '@apollo/client'
import { EmotionCache } from '@emotion/react'
import { isEmpty } from 'lodash'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { SnackbarProvider } from 'notistack'
import { Provider as ReduxProvider } from 'react-redux'

import '@components/ImageLightbox/styled.css'
import { withApollo, withRedux } from '@headless/core'
import { createEmotionCache } from '@headless/hooks'
import { fetchApp } from '@hooks/App'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import AppShell from '@components/AppShell'

type InitialPage = {
  Component: any
  ctx: any
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientEmotionCache = createEmotionCache()

interface HeadlessProps extends AppProps {
  apolloClient?: any
  emotionCache?: EmotionCache
  reduxStore?: any
}

const App = ({
  Component,
  apolloClient,
  emotionCache = clientEmotionCache,
  pageProps,
  reduxStore
}: HeadlessProps) => {
  const getSearchParam = (parameter = '', location: any = {}) => {
    const params = new URLSearchParams(location.search)

    return params.get(parameter) || ''
  }

  const router = useRouter()

  useEffect(() => {
    const { location } = window
    const storage = window.sessionStorage

    // mailchimp相关逻辑
    const mc_cid = getSearchParam('mc_cid', location)
    const mc_eid = getSearchParam('mc_eid', location)

    if (!mc_cid && !mc_eid) {
      return
    }

    if (
      storage.getItem('mc_cid') ||
      storage.getItem('mc_eid') ||
      storage.getItem('mc_lp')
    ) {
      // 已经存在数据
      storage.removeItem('mc_cid')
      storage.removeItem('mc_eid')
      storage.removeItem('mc_lp')
    }

    storage.setItem('mc_cid', mc_cid)
    storage.setItem('mc_eid', mc_eid)
    storage.setItem('mc_lp', location.pathname + location.search)
  }, [router])

  return (
    <>
      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="apple-mobile-web-app-title" content="Sweetv" />
        <meta name="format-detection" content="telephone=no, email=no" />
        <meta name="application-name" content="Sweetv" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Sweetv" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-TileColor" content="#2B5797" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="google-site-verification"
          content="9Dhvloyza9zdIUjC_m1U5KLtj7hKW813hnzu7HhkeqA"
        />

        <link rel="manifest" href="/manifest.json" />
        <link rel="dns-prefetch" href={process.env.API_URL} />
        <link
          rel="preconnect"
          href={process.env.API_URL}
          cross-origin="crossorigin"
        />
      </Head>
      <ApolloProvider client={apolloClient}>
        <ReduxProvider store={reduxStore}>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <AppShell cache={emotionCache}>
              <Component {...pageProps} />
            </AppShell>
          </SnackbarProvider>
        </ReduxProvider>
      </ApolloProvider>
    </>
  )
}

App.getInitialProps = async ({ Component, ctx }: InitialPage) => {
  const { reduxStore } = ctx
  const state = reduxStore.getState()

  if (isEmpty(state.app.storeConfig)) await fetchApp(ctx)

  const pageProps = Component.getInitialProps
    ? await Component.getInitialProps({ ...ctx })
    : {}

  return { pageProps }
}

export default withApollo(withRedux(App))
