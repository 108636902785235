import styled from '@emotion/styled'

interface StyledWrapperProps {
  isCheckout: boolean
}

export const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 199;
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
`

export const StyledContainer = styled.div`
  @media screen and (max-width: 1500px) and (min-width: 1366px) {
    padding: 0 2.5rem;
  }
`

export const StyledWrapper = styled.div<StyledWrapperProps>`
  position: relative;
  padding: ${(props: any) =>
    props.isCheckout
      ? '2.35rem calc((100% - 1600px)/2)'
      : '1.5325rem calc((100% - 1600px)/2)'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 350ms ease-in-out;

  &.hidden {
    height: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
  }

  @media screen and (max-width: 767px) {
    padding: ${(props: any) => (props.isCheckout ? '2.35rem 0' : '0.6rem 0')};
  }
`

export const StyledLogo = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  max-width: 50%;
  margin: 0 auto;
  z-index: 20;
  text-align: center;

  img {
    width: 7.125rem;
  }

  .MuiLink-root {
    display: inline-block;
  }

  picture {
    display: inline-block;
  }

  @media screen and (max-width: 767px) {
    img {
      display: block;
      width: 4.3125rem;
      margin: 0 auto;
      padding: 0rem;
    }
  }
`

export const StyledTools = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
`

export const StyledMiddle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding-left: 0.625rem;
    grid-column-gap: 0.75rem;
  }
`
