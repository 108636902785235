import { createAsyncThunk } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'

import { cookie, isInvalidCart } from '@headless/utils'

import { slice as checkoutSlice } from '../checkout/slice'
import { slice } from './slice'

const { actions } = slice

export const createEmptyCart = createAsyncThunk(
  'cart/createEmptyCart',
  async (payload: any, { dispatch }) => {
    try {
      const { fetchCartId } = payload
      const { data } = await fetchCartId({
        fetchPolicy: 'no-cache'
      })

      const cartId = data?.createEmptyCart ?? null
      await cookie.setItem('cart_id', cartId)
      await dispatch(actions.setCartId(cartId))
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error({
        message: `Unable to create cart`
      })
    }
  }
)

export const getCartDetails = createAsyncThunk(
  'cart/getCartDetails',
  async (payload: any, { dispatch, getState }) => {
    const { fetchCartId, fetchGuestCart, fetchCustomerCart } = payload
    const { cart, user }: any = getState()
    const { cartId } = cart
    const { isLogin } = user
    const fetchCartDetails = isLogin ? fetchCustomerCart : fetchGuestCart
    const cacheId = cookie.getItem('cart_id')

    if (isEmpty(cacheId)) {
      await dispatch(
        createEmptyCart({
          fetchCartId
        })
      )
    }
    const activeId = cookie.getItem('cart_id') || cartId

    try {
      const { data } = await fetchCartDetails({
        variables: { cartId: activeId }
      })
      const cartDetail: any = data.cart
      if (cartDetail.total_quantity > 0) {
        await dispatch(checkoutSlice.actions.setCheckoutState(cartDetail))
      }
      return cartDetail
    } catch (error: any) {
      const shouldResetCart = !error.networkError && isInvalidCart(error)

      if (shouldResetCart) {
        await dispatch(
          createEmptyCart({
            fetchCartId
          })
        )

        const { data } = await fetchCartDetails({
          variables: { cartId: activeId }
        })
        const cartDetail: any = isLogin ? data.customerCart : data.cart
        return cartDetail
      }
    }
  }
)

export const addProductsToCart = createAsyncThunk(
  'cart/addProductsToCart',
  async (payload: any, { dispatch, getState }) => {
    const {
      fetchCartId,
      fetchGuestCart,
      fetchCustomerCart,
      addToCartMutation,
      addConfigurableMutation,
      cartItems,
      __typename
    } = payload

    const { cart, user }: any = getState()
    const { cartId } = cart
    const { isLogin } = user
    const fetchCartDetails = isLogin ? fetchCustomerCart : fetchGuestCart
    const cacheId = cookie.getItem('cart_id') || 'undefined'
    const cart_id = cacheId === cartId ? cartId : cacheId
    const addCartMutation =
      __typename === 'ConfigurableProduct'
        ? addConfigurableMutation
        : addToCartMutation

    try {
      const { data } = await addCartMutation({
        variables: {
          cartId: cart_id,
          cartItems
        }
      })

      const cartDetail = data.addToCart.cart
      return cartDetail
    } catch (error: any) {
      const shouldResetCart = !error.networkError && isInvalidCart(error)
      if (shouldResetCart) {
        await dispatch(
          createEmptyCart({
            fetchCartId
          })
        )
        const { data } = await fetchCartDetails({
          variables: { cartId: cookie.getItem('cart_id') }
        })
        const cartDetail: any = isLogin ? data.customerCart : data.cart
        return cartDetail
      }
    }
  }
)

export const updateCartItem = createAsyncThunk(
  'cart/updateCartItem',
  async (payload: any, { dispatch, getState }) => {
    const {
      customizable,
      id,
      quantity,
      fetchCartId,
      fetchGuestCart,
      fetchCustomerCart,
      updateCartMutaion
    } = payload
    const { cart, user }: any = getState()
    const { cartId } = cart
    const { isLogin } = user
    const fetchCartDetails = isLogin ? fetchCustomerCart : fetchGuestCart
    const cacheId = cookie.getItem('cart_id') || 'undefined'
    const cart_id = cacheId === cartId ? cartId : cacheId

    try {
      const { data } = await updateCartMutaion({
        variables: {
          cartId: cart_id,
          cartItems: [
            {
              cart_item_id: id,
              quantity,
              selected_options: customizable
            }
          ]
        }
      })

      const cartDetail = data.updateCart.cart
      return cartDetail
    } catch (error: any) {
      const shouldResetCart = !error.networkError && isInvalidCart(error)
      if (shouldResetCart) {
        if (!isLogin)
          await dispatch(
            createEmptyCart({
              fetchCartId
            })
          )
        const { data } = await fetchCartDetails({
          variables: { cartId: cookie.getItem('cart_id') }
        })
        const cartDetail: any = isLogin ? data.customerCart : data.cart
        return cartDetail
      }
    }
  }
)

export const removeCartItem = createAsyncThunk(
  'cart/removeCartItem',
  async (payload: any, { dispatch, getState }) => {
    const {
      id,
      fetchCartId,
      fetchGuestCart,
      fetchCustomerCart,
      removeCartMutation
    } = payload
    const { cart, user }: any = getState()
    const { cartId } = cart
    const { isLogin } = user
    const fetchCartDetails = isLogin ? fetchCustomerCart : fetchGuestCart
    const cacheId = cookie.getItem('cart_id') || 'undefined'
    const cart_id = cacheId === cartId ? cartId : cacheId

    try {
      const { data } = await removeCartMutation({
        variables: {
          cart_id,
          cart_item_id: id
        }
      })

      const cartDetail = data.removeCart.cart
      return cartDetail
    } catch (error: any) {
      const shouldResetCart = !error.networkError && isInvalidCart(error)
      if (shouldResetCart) {
        if (!isLogin)
          await dispatch(
            createEmptyCart({
              fetchCartId
            })
          )
        const { data } = await fetchCartDetails({
          variables: { cartId: cookie.getItem('cart_id') }
        })
        const cartDetail: any = isLogin ? data.customerCart : data.cart
        return cartDetail
      }
    }
  }
)
