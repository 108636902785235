export const isInvalidCart: Function = (error: any) => {
  return !!(
    error.graphQLErrors &&
    error.graphQLErrors.find(
      (err: any) =>
        err.message.includes('Could not find a cart') ||
        err.message.includes("The cart isn't active") ||
        err.message.includes('The request is allowed for logged in customer') ||
        err.message.includes(
          'The current user cannot perform operations on cart'
        )
    )
  )
}
