import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'

import { NEWSLETTER_MUTATION } from '@graphql/mutations/subscribeNewsletter'

export const useSubscribe = () => {
  const [newsletterPost] = useMutation(NEWSLETTER_MUTATION)
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      newsletter: ''
    }
  })
  const [visible, setVisible] = useState(false)

  const handleFormSubmit = handleSubmit(async (values: any) => {
    try {
      const { data } = await newsletterPost({
        variables: {
          email: values.newsletter
        }
      })

      const status = data?.subscribe?.status ?? ''
      if (status === 'SUBSCRIBED') {
        setVisible(true)
      }
    } catch (error: any) {
      setVisible(false)
    }
  })

  return {
    control,
    errors,
    visible,
    handleFormSubmit
  }
}
