import { useLazyQuery } from '@apollo/client'

import debounce from 'lodash/debounce'
import { useEffect, useMemo } from 'react'

export const useAutocomplete = (props: any) => {
  const {
    queries: { getAutocompleteResults },
    valid,
    visible,
    setSearchResult,
    searchValue
  } = props

  // const [, { dispatch }] = useEventingContext()

  // Prepare to run the queries.
  const [runSearch, productResult] = useLazyQuery(getAutocompleteResults, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  // Create a debounced function so we only search some delay after the last
  // keypress.
  const debouncedRunQuery = useMemo(
    () =>
      debounce((inputText: string) => {
        runSearch({
          variables: { inputText }
        })
      }, 500),
    [runSearch]
  )

  // run the query once on mount, and again whenever state changes
  useEffect(() => {
    if (valid && visible) {
      debouncedRunQuery(searchValue as string)

      if (searchValue) {
        // TODO:
        // dispatch({
        //   type: 'SEARCHBAR_REQUEST',
        //   payload: {
        //     query: value,
        //     currentPage: 1, // Same value used in GQL query
        //     pageSize: 4, // Same value used in GQL query
        //     refinements: []
        //   }
        // })
      }
    }
  }, [debouncedRunQuery, valid, searchValue, visible])

  const { data, error, loading } = productResult

  useEffect(() => {
    if (data) {
      setSearchResult(data.products)
    }
  }, [data, setSearchResult])

  // Handle results.
  const categories = data && data.products?.aggregations[1]?.options
  const products = data && data.products
  const filters = (data && data.products?.aggregations) || []
  const hasResult = products && products.items
  const resultCount = products && products.total_count
  const displayResult = valid && hasResult
  const invalidCharacterLength = !!(!valid && searchValue)
  let messageType = ''

  if (invalidCharacterLength) {
    messageType = 'INVALID_CHARACTER_LENGTH'
  } else if (error) {
    messageType = 'ERROR'
  } else if (loading) {
    messageType = 'LOADING'
  } else if (!displayResult) {
    messageType = 'PROMPT'
  } else if (!resultCount) {
    messageType = 'EMPTY_RESULT'
  } else {
    messageType = 'RESULT_SUMMARY'
  }

  return {
    categories,
    displayResult,
    filters,
    messageType,
    products,
    resultCount,
    searchValue
  }
}
