import styled from '@emotion/styled'

export const StyledMenu = styled.nav`
  padding: 0 calc((100% - 1440px) / 2);

  .nav {
    &__menu {
      display: flex;
      justify-content: center;
    }

    &__column {
      height: 3.25rem;
      display: flex;
      padding: 0 2.8125rem;
      align-items: center;

      &:hover {
        .dropdown-mega-menu {
          opacity: 1;
          visibility: visible;
          transform: translate(-50%, 0);
        }
      }
    }

    &__item {
      > a {
        position: relative;
        font-size: 1rem;
        font-weight: 500;
        text-decoration: none;
        color: #000;
        font-family: 'karla';

        &:after {
          content: '';
          display: block;
          left: 0;
          right: 0;
          min-width: 100%;
          position: absolute;
          top: 100%;
          margin-top: 3px;
          height: 2px;
          opacity: 0;
          visibility: visible;
          transition: all 300ms;
          transform: scaleX(0);
        }

        &:hover {
          color: #212529;

          &:after {
            opacity: 1;
            visibility: visible;
            transform: scaleX(1);
            background-color: #212529;
          }
        }
      }

      &__5 {
        .dropdown-mega-menu {
          .mega-menu-content {
            max-width: 1000px;
          }
        }

        .line {
          flex: 0 0 50%;

          > .col-des-12 {
            flex-basis: 50%;
          }

          &.banner {
            display: grid;
            flex: 0 0 50%;
            grid-template-columns: repeat(2, 1fr);
            justify-content: space-between;
          }
        }
      }
    }
  }

  .dropdown-mega-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 100%;
    padding: 40px 35px 40px;
    transform: translate(-50%, 20px);
    max-height: calc(100vh - 100px);
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgb(35 31 32 / 10%);
    transition: all 200ms;

    .mega-menu-content {
      max-width: 880px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .menu-col {
          display: flex;
          flex-direction: column;
          .link-text {
            display: inline-block;
            padding: 4px 0;
            color: #615956;
            font-size: 14px;
            line-height: 20px;
            font-family: karla;
            text-decoration: none;

            &:hover {
              color: #dbb19f;
            }
          }
          > .sub-menu {
            .sub-menu {
              li:first-of-type {
                font-family: SilkSerif;
                font-size: 18px;
                line-height: 28px;
                color: #212529;
                strong {
                  font-weight: 400 !important;
                  color: #212529;
                }
              }
            }
          }
        }
      }
    }

    .mega-menu-content-1 {
      max-width: 1350px !important;
    }
    .mega-menu-content-2 {
      max-width: 1080px !important;
    }
    .mega-menu-content-3 {
      max-width: 1080px !important;
    }
    .mega-menu-content-4 {
      max-width: 880px !important;
    }
    .mega-menu-content-5 {
      max-width: 1080px !important;
    }
  }

  .sub-menu {
    > li {
      > a {
        display: inline-block;
        padding: 4px 0;
        color: #615956;
        font-size: 14px;
        line-height: 20px;
        font-family: karla;
        text-decoration: none;

        &:hover {
          color: #212529;
          font-weight: 700;
          text-decoration: underline;
        }
      }

      &:first-of-type {
        padding: 4px 0;
        > a {
          font-family: SilkSerif;
          font-size: 18px;
          line-height: 28px;
          color: #212529;
          padding: 0 !important;
        }
      }
      &.bold {
        > a {
          font-size: 18px;
          font-family: SilkSerif;
          color: #212529;
        }
      }
    }
  }

  @media only screen and (min-width: 992px) {
    .toggle-menu {
      display: none;
    }

    .col-mb-12 {
      flex: 0 1 100%;
      max-width: 100%;
    }

    .col-des-12 {
      flex: 0 1 100%;
      max-width: 100%;
    }

    .col-des-6 {
      flex: 0 1 50%;
      max-width: 50%;
    }
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`
