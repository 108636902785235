import { gql } from '@apollo/client'

export const CartBannerFragment = gql`
  fragment CartBannerFragment on Cart {
    banners {
      id
      title
      p_pic
      m_pic
      link
      position
    }
  }
`
