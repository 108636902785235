import { Loader } from 'react-feather'

import { Icon } from '@headless/ui'
import { usePageLoading } from '@hooks/PageLoading'

import { StyledPageLoading } from './styled'

const PageLoading = () => {
  const { pageLoading } = usePageLoading()

  return pageLoading ? (
    <StyledPageLoading>
      <Icon src={Loader} className="indicator" size={40} />
    </StyledPageLoading>
  ) : null
}

export default PageLoading
