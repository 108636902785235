import { gql } from '@apollo/client'

import { configurableCart } from './configurableCart'
import { price_range } from './priceRange'

export const cartItems = gql`
  fragment cartItems on Cart {
    items {
      id
      ... on BundleCartItem {
        bundle_options {
          uid
          label
          type
          values {
            id
            label
            price
            quantity
          }
        }
      }
      product {
        id
        name
        sku
        type_id
        url_key
        uid
        thumbnail {
          label
          url
        }
        presale_product {
          status
          label
          note
        }
        price_range {
          ...price_range
          __typename
        }
        ... on ConfigurableProduct {
          variants {
            ... on ConfigurableVariant {
              attributes {
                code
                uid
              }
              product {
                sku
                presale_product {
                  status
                  label
                  note
                }
                thumbnail {
                  label
                  url
                }
              }
            }
            __typename
          }
        }
        __typename
      }
      select_product_image
      quantity
      prices {
        discounts {
          amount {
            value
          }
        }
        row_total {
          value
        }
        price {
          value
        }
      }
      ...configurableCart
      __typename
    }
  }
  ${price_range}
  ${configurableCart}
`
