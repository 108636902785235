import styled from '@emotion/styled'

export const StyledMain = styled.main`
  min-height: 80vh;
  padding-bottom: 3rem;

  @media screen and (max-width: 640px) {
    min-height: 55vh;
  }
`

export const StyleAutoHeightMain = styled.main`
  min-height: 50vh;
  padding-bottom: 6rem;

  @media screen and (max-width: 640px) {
    min-height: 55vh;
    padding-bottom: 3em;
  }
`
