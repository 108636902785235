import styled from '@emotion/styled'

export const StyledNav = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 3rem;
  justify-content: center;
  align-items: center;

  > li {
    padding: 0.85rem 0;

    > a {
      position: relative;
      color: #222;
      font-size: 0.95rem;
      text-transform: uppercase;
      text-decoration: none;

      &:after {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        content: '';
        display: block;
        margin-top: 3px;
        height: 2px;
        opacity: 0;
        background-color: #000;
        transition: all 300ms;
        transform: scaleX(0);
      }

      &:hover {
        &:after {
          opacity: 1;
          background-color: #e5c6b9;
          transform: scaleX(1);
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    grid-column-gap: 1rem;
  }
`
