import { Icon } from '@headless/ui'
import { useAnnouncementsList } from '@hooks/AnnouncementsList'
import parse from 'html-react-parser'
import { memo } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import Slider from 'react-slick'
import { StyleAnnouncementsList } from './styled'

const AnnouncementsList = () => {
  const { announcementsData } = useAnnouncementsList()

  function SampleNextArrow(props: any) {
    const { onClick } = props

    return (
      <div className="rightNav" onClick={onClick}>
        <Icon src={ChevronRight} className="navIcon" size={15} />
      </div>
    )
  }

  function SamplePrevArrow(props: any) {
    const { onClick } = props
    return (
      <div className="leftNav" onClick={onClick}>
        <Icon src={ChevronLeft} className="navIcon" size={15} />
      </div>
    )
  }

  const sliderProps = {
    fade: false,
    dots: false,
    pauseOnHover: true,
    autoplaySpeed: 3000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  return announcementsData.length > 0 ? (
    <StyleAnnouncementsList>
      <ul className="container">
        <Slider {...sliderProps}>
          {announcementsData.map((item: any) => {
            return <li key={item.id}>{parse(item.content)}</li>
          })}
        </Slider>
      </ul>
    </StyleAnnouncementsList>
  ) : null
}

export default memo(AnnouncementsList)
