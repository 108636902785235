import { useQuery } from '@apollo/client'
import { GET_CMS_ANNOUNCEMENT } from '@graphql/queries/getCmsAnnouncements'

export const useAnnouncementsList = () => {
  const { loading, error, data } = useQuery(GET_CMS_ANNOUNCEMENT, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })
  const announcementsData = data?.CmsAnnouncements || []

  return {
    loading,
    error,
    announcementsData
  }
}
