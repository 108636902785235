import { useFormApi } from 'informed'
import { useCallback, useEffect, useRef } from 'react'
import useFieldState from 'workspaces/hooks/useInformedFieldStateWrapper'

const getSearchParam = (parameter = '', location: any = {}) => {
  const params = new URLSearchParams(location.search)

  return params.get(parameter) || ''
}

interface useSearchFieldProps {
  isSearchOpen: boolean
}
/**
 * Returns props necessary to render a SearchField component.
 */
export const useSearchField = (props: useSearchFieldProps) => {
  const { isSearchOpen } = props

  const inputRef = useRef()
  const { value } = useFieldState('search_query')
  const formApi = useFormApi()

  const resetForm = useCallback(() => {
    formApi.reset()
  }, [formApi])

  // When the search field is opened focus on the input.
  useEffect(() => {
    if (isSearchOpen && inputRef.current) {
      ;(inputRef.current as unknown as any).focus()
    }
  }, [isSearchOpen])

  // Pre-populate the search field with the search term from the URL.
  // We purposefully only ever run this effect on initial mount.
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const urlTerm = getSearchParam('query', location)

    if (!formApi || !urlTerm) {
      return
    }

    formApi.setValue('search_query', urlTerm)
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return {
    inputRef,
    resetForm,
    value
  }
}
