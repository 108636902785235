import { useMediaQuery } from '@mui/material'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useDropdown } from 'workspaces/hooks/useDropdown'

const initialValues = { search_query: '' }

interface useSearchBarProps {
  setIsSearchOpen: (isOpen: boolean) => void
  isOpen: boolean
}

export const useSearchBar = (props: useSearchBarProps) => {
  const { setIsSearchOpen, isOpen } = props
  const [valid, setValid] = useState(false)

  const isMobile: boolean = useMediaQuery('(max-width:767px)')

  const [searchValue, setSearchValue] = useState('')

  const [searchResult, setSearchResult] = useState<{
    items: Array<any>
  } | null>(null)

  const {
    elementRef,
    expanded: isAutoCompleteOpen,
    setExpanded: setIsAutoCompleteOpen
  } = useDropdown()
  const router = useRouter()

  const { push } = router

  useEffect(() => {
    if (isOpen && isMobile) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen, isMobile])

  const handleChange = useCallback(
    (event: any) => {
      const { value } = event.target
      const hasValue = !!value
      const isValid = hasValue && value.length > 2

      if (isValid) {
        setSearchValue(value)
      }

      setValid(isValid)
      setIsAutoCompleteOpen(hasValue)
    },
    [setIsAutoCompleteOpen, setValid, setSearchValue]
  )

  // expand on focus
  const handleFocus = useCallback(() => {
    setIsAutoCompleteOpen(true)
  }, [setIsAutoCompleteOpen])

  // navigate on submit
  const handleSubmit = useCallback(() => {
    if (searchResult?.items.length === 1) {
      push(
        `/${searchResult.items[0].url_key}${searchResult.items[0].url_suffix}`
      )
      setIsAutoCompleteOpen(false)
      setIsSearchOpen(false)
    } else if (searchValue != null && searchValue.trim().length > 0) {
      push(`/search?q=${searchValue}`)
      setIsAutoCompleteOpen(false)
      setIsSearchOpen(false)
    }
  }, [push, searchResult, searchValue, setIsAutoCompleteOpen, setIsSearchOpen])

  return {
    containerRef: elementRef,
    handleChange,
    handleFocus,
    handleSubmit,
    initialValues,
    isAutoCompleteOpen,
    setIsAutoCompleteOpen,
    setValid,
    valid,
    setSearchResult,
    searchValue
  }
}
