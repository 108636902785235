import { StyledProductList } from './styled'
import SuggestedProduct from './suggestedProduct'

const mapProduct = (product: any) => {
  const { description, small_image } = product

  const newDescription =
    typeof description === 'object' ? description.html : description
  const newSmallImage =
    typeof small_image === 'object' ? small_image.url : small_image

  return {
    ...product,
    description: newDescription,
    small_image: newSmallImage
  }
}

const SuggestedProducts = (props: any) => {
  const { limit = 4, onNavigate, products, onViewResult } = props

  const items = products.slice(0, limit).map((product: any) => (
    <li key={product.id}>
      <SuggestedProduct
        {...mapProduct(product)}
        onNavigate={onNavigate}
        onViewResult={onViewResult}
      />
    </li>
  ))

  return <StyledProductList>{items}</StyledProductList>
}

export default SuggestedProducts
