import { useSuggestions } from '@hooks/SearchBar'
import SuggestedProducts from './suggestedProducts'

const Suggestions = (props: any) => {
  const {
    displayResult,
    filters,
    products,
    setVisible,
    visible,
    onViewResult
  } = props

  const { items } = products

  const talonProps = useSuggestions({
    displayResult,
    filters,
    items,
    setVisible,
    visible
  })
  const { onNavigate, shouldRender } = talonProps

  // render null without data
  if (!shouldRender) {
    return null
  }

  return (
    <>
      <SuggestedProducts
        onNavigate={onNavigate}
        products={items}
        onViewResult={onViewResult}
      />
    </>
  )
}

export default Suggestions
