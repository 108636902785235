import { FC, memo } from 'react'

import { useBasePrice } from '@hooks/BasePrice'
import { CrossedPrice, StyledPrice } from './styled'

interface BasePriceProps {
  className?: string
  decimal?: number
  delimiter?: string
  digit?: number
  round?: boolean
  value: number
  crossed?: boolean
}

const BasePrice: FC<BasePriceProps> = ({
  decimal = 2,
  delimiter = ',',
  digit = 3,
  round = true,
  value,
  crossed = false,
  ...props
}) => {
  const { price } = useBasePrice({
    decimal,
    delimiter,
    digit,
    round,
    value
  })

  const Component = crossed ? CrossedPrice : StyledPrice

  return <Component {...props}>{price}</Component>
}

export default memo(BasePrice)
