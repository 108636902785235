import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@headless/ui'
import { event } from '@headless/utils'

import BasePrice from '@components/BasePrice'
import { actions as cartActions } from '@store/cart'
import { useDispatch } from 'react-redux'

import { StyledCartContent, StyledCartModal } from './styled'

const CartModal = () => {
  const router = useRouter()
  const dispatch = useDispatch()

  const [, setOpen] = useState<boolean>(false)
  const [cartItem, setCartItem] = useState<any>(null)

  const handleClose = async () => {
    setOpen(false)
    dispatch(cartActions.setShowMiniCart(false))
  }

  const handleConfirm = () => {
    router.push('/checkout/cart')
    setOpen(false)
    dispatch(cartActions.setShowMiniCart(false))
  }

  useEffect(() => {
    event.on('receiveCartModal', (payload: any) => {
      if (payload) {
        setCartItem(payload)
        setOpen(true)
        dispatch(cartActions.setShowMiniCart(true))

        setTimeout(() => {
          dispatch(cartActions.setShowMiniCart(false))
        }, 2000)
      }
    })

    return () => {
      event.off('receiveCartModal', (payload: any) => {
        if (payload) setCartItem(payload)
      })
    }
  }, [dispatch])

  return (
    <Dialog
      className="modal__cart"
      maxWidth={false}
      open={false} // 不要这个弹窗
      onClose={handleClose}
    >
      <StyledCartModal>
        <DialogTitle>
          <span>1 item added to your cart</span>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!isEmpty(cartItem) && (
            <StyledCartContent>
              <picture>
                <img src={cartItem?.image?.url} alt={cartItem?.image?.label} />
              </picture>
              <div className="cartmodal__content">
                <p className="name">
                  <span dangerouslySetInnerHTML={{ __html: cartItem.name }} />
                </p>
                <p>
                  <span className="title">Quantity:</span>
                  <span>{cartItem.quantity}</span>
                </p>
                <p>
                  <span className="title">Cart Subtotal:</span>
                  <BasePrice value={cartItem.quantity * cartItem.total} />
                </p>
              </div>
            </StyledCartContent>
          )}
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={handleClose}>
            Continue Shopping
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={handleConfirm}
            autoFocus
          >
            View Cart
          </Button>
        </DialogActions>
      </StyledCartModal>
    </Dialog>
  )
}

export default CartModal
