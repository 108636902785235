import { css, Global } from '@emotion/react'
import { FC } from 'react'

const GlobalStyles = css`
  @font-face {
    font-family: 'karla';
    src: local('karla'), local('karla'), url('/fonts/karla.otf');
    src: local('karla'), local('karla'),
      url('/fonts/karla.otf?#iefix') format('embedded-opentype'), local('karla'),
      local('karla'), url('/fonts/karla.woff') format('woff'), local('karla'),
      local('karla'), url('/fonts/karla.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Constantia';
    src: local('Constantia'), local('Constantia'),
      url('/fonts/Constantia.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
  }

  @font-face {
    font-family: 'serif';
    src: local('serif'), local('serif'), url('/fonts/silk-serif.otf');
    src: local('serif'), local('serif'),
      url('/fonts/silk-serif.otf?#iefix') format('embedded-opentype'),
      local('serif'), local('serif'),
      url('/fonts/silk-serif.woff') format('woff'), local('serif'),
      local('serif'), url('/fonts/silk-serif.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
  }

  @font-face {
    font-family: 'SilkSerif';
    src: local('serif'), local('serif'), url('/fonts/silk-serif.otf');
    src: local('serif'), local('serif'),
      url('/fonts/silk-serif.otf?#iefix') format('embedded-opentype'),
      local('serif'), local('serif'),
      url('/fonts/silk-serif.woff') format('woff'), local('serif'),
      local('serif'), url('/fonts/silk-serif.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  ul,
  ol {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;

    li {
      list-style-type: none;
    }
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0.5rem;
  }

  address {
    font-style: normal;
  }

  img {
    max-width: 100%;
    height: auto;
    border: 0;
  }

  a {
    color: #52575d;
    transition: all 300ms;

    &:hover {
      color: #dbb19f;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
  }

  .MuiFormLabel-asterisk {
    color: #d22c2c;
  }

  .Mui-disabled {
    > span {
      opacity: 0;
      visibility: hidden;
    }
  }

  .social-links {
    display: flex;
  }

  .print {
    main {
      padding-bottom: 0;
    }
  }

  body {
    .SnackbarContainer-bottom {
      .SnackbarItem-variantSuccess {
        color: #000;
        background-color: #f2e0d6;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .modal__notify,
    .modal__cart {
      .MuiPaper-root {
        margin: 0;
        max-width: calc(100% - 0.5rem * 2);
        max-height: calc(100% - 0.5rem * 2);
      }
    }
  }
`

const GlobalStyled: FC = () => {
  return <Global styles={GlobalStyles} />
}

export default GlobalStyled
