import styled from '@emotion/styled'

export const StyledCartPop = styled.div`
  cursor: pointer;
  padding-right: 0.75rem;

  .MuiBadge-badge {
    cursor: pointer;
    min-width: 0.9375rem;
    height: 0.9375rem;
    font-size: 0.625rem;
    font-weight: 600;
    background-color: #dbb19f;
  }

  .hidden {
    cursor: pointer;
    .MuiBadge-badge {
      opacity: 0;
    }
  }
`

export const StyledMiniCart = styled.div`
  width: 25rem;
  padding: 1rem;

  .minicart {
    &__title {
      padding-bottom: 0.625rem;
      font-size: 1.125rem;
      font-family: 'serif';
      font-weight: 500;
      border-bottom: 1px solid #eaeaea;
    }
  }

  &.minicart__drawer {
    width: 100%;
    height: 100%;

    .empty {
      padding: 8rem 0 0;
    }
  }

  .MuiButton-containedPrimary {
    position: fixed;
    right: 0.9375rem;
    bottom: 45vh;
    left: 0.9375rem;
    width: calc(100% - 1.875rem);
    padding: 0.75rem 1.25rem;
    color: #222;
    font-size: 1rem;
    z-index: 30;
    text-transform: capitalize;
    border-radius: 0;
    background-color: #f2e0d6;

    &.bottom {
      bottom: 14vh;
    }
  }
`

export const StyledEmpty = styled.p`
  margin-bottom: 0;
  padding: 2rem 0;
  color: #222;
  font-style: italic;
  font-weight: 600;
  font-size: 0.875rem;
  text-align: center;
`

export const StyledTotal = styled.div`
  display: flex;
  margin: 1rem 0;
  justify-content: space-between;
  align-items: center;

  .label {
    color: #000;
    font-weight: 500;
    font-size: 1.125rem;
  }

  .price {
    font-weight: 500;
    font-size: 1.125rem;
  }
`

export const StyledCartAction = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 1rem;
  justify-content: center;

  .MuiTypography-root {
    display: block;
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
  }

  .checkout {
    padding: 0.65rem;
    font-size: 1rem;
    text-decoration: none;
    background-color: #f2e0d6;
    border: 1px solid #f2e0d6;
  }
`
