import { FC, forwardRef } from 'react'

import { StyledIcon } from './styled'

interface IconProps {
  className?: string
  src: any
  size?: number
  onClick?: any
}

const Icon: FC<IconProps> = forwardRef(
  ({ src, size, onClick, ...props }, ref) => {
    const Component: any = src

    return (
      <StyledIcon onClick={onClick}>
        <Component ref={ref} size={size} {...props} />
      </StyledIcon>
    )
  }
)

Icon.displayName = 'Icon'

export default Icon
