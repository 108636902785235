import { useQuery } from '@apollo/client'
import { debounce } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { GET_CATEGOR_TREE } from '@graphql/queries/getCategoryTree'

export const useNavigation = () => {
  const { data } = useQuery(GET_CATEGOR_TREE)

  console.log({ data })
  const storeConfig = useSelector((state: any) => state.app.storeConfig)

  const suffix = useMemo(() => {
    return storeConfig?.category_url_suffix ?? ''
  }, [storeConfig])

  const categories = data?.categoryList[0].children ?? []

  useEffect(() => {
    const handleScrool = debounce(() => {
      const target: any = document.querySelector('.headless__area')
      if (!target) return false
      const height: number = target.offsetTop + target.clientHeight
      const scrollTop: number =
        document.body.scrollTop || document.documentElement.scrollTop

      if (scrollTop > height) {
        target.classList.add('hidden')
      } else {
        target.classList.remove('hidden')
      }
    }, 50)

    window.addEventListener('scroll', handleScrool)

    return () => {
      window.removeEventListener('scroll', handleScrool)
    }
  }, [])

  return {
    categories,
    suffix
  }
}
