import AppContextProvider from '../app'
import CartContextProvider from '../cart'
import LocaleContextProvider from '../i18n'
import UserContextProvider from '../user'

const contextProviders = [
  LocaleContextProvider,
  AppContextProvider,
  CartContextProvider,
  UserContextProvider
]

const HeadlessContextProvider = ({ children }: any) => {
  return contextProviders.reduceRight((memo, ContextProvider) => {
    return <ContextProvider>{memo}</ContextProvider>
  }, children)
}

export default HeadlessContextProvider
