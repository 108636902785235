import { FC } from 'react'

import { Link } from '@headless/ui'
import { useNavigation } from '@hooks/Navigation'

import { StyledNav } from './styled'

const Navigation: FC = () => {
  const { categories, suffix } = useNavigation()

  return (
    <nav className="navigation" role="navigation">
      {categories.length > 0 && (
        <StyledNav className="nav-category-tree">
          {categories.map((category: any) => {
            const { id, name, include_in_menu, url_path } = category
            return include_in_menu === 1 ? (
              <li key={id}>
                <Link
                  className="link"
                  href={`/${url_path}${suffix}`}
                  title={name}
                >
                  <span dangerouslySetInnerHTML={{ __html: name }} />
                </Link>
              </li>
            ) : null
          })}
        </StyledNav>
      )}
    </nav>
  )
}

export default Navigation
