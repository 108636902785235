import { FC } from 'react'

import InputAdornment, {
  InputAdornmentProps
} from '@mui/material/InputAdornment'

export const HeadlessInputAdornment: FC<InputAdornmentProps> = ({
  children,
  ...props
}) => {
  return <InputAdornment {...props}>{children}</InputAdornment>
}
