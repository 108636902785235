/* eslint-disable @next/next/no-img-element */
import Price from '@components/Price'
import { useSuggestedProduct } from '@hooks/SearchBar'
import { StyledProductLink } from './styled'

const IMAGE_WIDTH = 220

const SuggestedProduct = (props: any) => {
  const {
    url_key,
    small_image,
    name,
    onNavigate,
    price,
    price_range,
    url_suffix,
    sku,
    onViewResult
  } = props

  const talonProps = useSuggestedProduct({
    name,
    price,
    price_range,
    onNavigate,
    url_key,
    url_suffix,
    sku
  })

  const { priceProps, uri } = talonProps

  return (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <StyledProductLink
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      href={uri!}
      onClick={() => {
        onViewResult(url_key, url_suffix)
      }}
    >
      <img alt={name} src={small_image} width={IMAGE_WIDTH} />
      <p>{name}</p>
      <p>
        <Price {...priceProps} />
      </p>
    </StyledProductLink>
  )
}

export default SuggestedProduct
