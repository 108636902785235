import patches from '@headless/utils/i18n/intlPatches'
import { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'

type PriceProps = {
  value: number
  currencyCode: string
}

const Price: FC<PriceProps> = (props: PriceProps) => {
  const storeConfig = useSelector((state: any) => state.app.storeConfig)

  const locale = useMemo(() => {
    const lang = storeConfig.locale || ''
    return lang.replace(/\_/g, '-')
  }, [storeConfig])

  const { value, currencyCode } = props

  const parts = patches.toParts.call(
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode
    }),
    value
  )

  const children = parts.map((part: any, i: number) => {
    const key = `${i}-${part.value}`

    return <span key={key}>{part.value}</span>
  })

  return <>{children}</>
}

export default Price
