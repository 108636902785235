import { createContext, useContext, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'

import { GET_CUSTOMER_DETAIL } from '@graphql/queries/getCustomerDetail'
import {
  actions as userActions,
  asyncActions as userAsyncActions
} from '@store/user'

import { useAwaitQuery } from '@headless/hooks'
import { cookie } from '@headless/utils'

const UserContext = createContext({})

const UserContextProvider = (props: any) => {
  const { actions, userState, asyncActions, children } = props
  const { isLogin } = userState

  const userApi = useMemo(
    () => ({
      ...actions,
      ...asyncActions
    }),
    [actions, asyncActions]
  )

  const contextValue = useMemo(() => [userState, userApi], [userApi, userState])

  const fetchUser = useAwaitQuery(GET_CUSTOMER_DETAIL)

  useEffect(() => {
    if (isLogin) {
      userApi.getUserDetails({ fetchUser }).then((res: any) => {
        if (res?.error) {
          cookie.removeItem('access_token')
          cookie.removeItem('cart_id')
          window.location.href = '/account/login'
        }
      })
    }
  }, [fetchUser, isLogin, userApi])

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  )
}

const mapStateToProps = ({ user }: any) => ({ userState: user })

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(userActions, dispatch),
  asyncActions: bindActionCreators(userAsyncActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UserContextProvider)

export const useUserContext = () => useContext(UserContext)
