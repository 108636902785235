import { createSlice, Slice } from '@reduxjs/toolkit'

export const slice: Slice = createSlice({
  name: 'catalog',
  initialState: {
    catalogSelect: null
  },
  reducers: {
    setCatalogSelect: (state: any, { payload }) => {
      state.catalogSelect = payload
    }
  }
})
