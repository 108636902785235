import useMediaQuery from '@mui/material/useMediaQuery'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { WebsiteConf } from '@config/website'
import { event } from '@headless/utils'
import { useDropdown } from 'workspaces/hooks/useDropdown'

export const useHeader = () => {
  const router = useRouter()
  const isShowMenu: boolean = useMediaQuery('(max-width:767px)')
  const isShowCurrency: boolean = useMediaQuery('(min-width:767px)')
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const { isCustomized } = WebsiteConf

  const {
    elementRef: searchRef,
    expanded: isSearchOpen,
    setExpanded: setIsSearchOpen,
    triggerRef: searchTriggerRef
  } = useDropdown()

  useEffect(() => {
    setIsSearchOpen(false)
  }, [router, setIsSearchOpen])

  const isCheckout: boolean = useMemo(() => {
    return router?.route === '/checkout'
  }, [router])

  const hideNav = () => {
    const navEle: any = document.querySelector('.nav__layer')
    const navShade: any = document.querySelector('.nav__shade')
    if (navEle) {
      navEle.classList.remove('active')
      navShade.classList.remove('active')
      setIsVisible(false)
    }
  }

  const handleToggle = () => {
    const navEle: any = document.querySelector('.nav__layer')
    const navShade: any = document.querySelector('.nav__shade')

    if (navEle) {
      if (isVisible || navEle.classList.contains('active')) {
        navEle.classList.remove('active')
        navShade.classList.remove('active')
        setIsVisible(false)
      } else {
        navEle.classList.add('active')
        navShade.classList.add('active')
        setIsVisible(true)
      }
    }
  }

  useEffect(() => {
    const navShade: any = document.querySelector('.nav__shade')
    const handleCloseDrawer = () => {
      setIsVisible(false)
      navShade.classList.remove('active')
    }

    event.on('closeDrawer', handleCloseDrawer)

    return () => {
      event.off('closeDrawer', handleCloseDrawer)
    }
  }, [])

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflowY = 'hidden'
      document.body.style.height = '100vh'
      document.getElementById('__next')!.style.height = '100%'
      document.getElementById('__next')!.style.overflowY = 'hidden'
      document.getElementsByTagName('header')[0]!.style.position = 'fixed'
    } else {
      document.body.style.height = 'auto'
      document.getElementById('__next')!.style.height = 'auto'
      document.body.style.overflowY = 'visible'
      document.getElementById('__next')!.style.overflowY = 'visible'
      document.getElementsByTagName('header')[0]!.style.position = 'sticky'
    }
  }, [isVisible])

  const handleSearchTriggerClick = useCallback(() => {
    setIsSearchOpen((isOpen: boolean) => !isOpen)
  }, [setIsSearchOpen])

  return {
    isCustomized,
    isCheckout,
    isShowMenu,
    isShowCurrency,
    isVisible,
    setIsVisible,
    handleToggle,
    hideNav,
    handleSearchTriggerClick,
    isSearchOpen,
    setIsSearchOpen,
    searchRef,
    searchTriggerRef
  }
}
