import { createAsyncThunk } from '@reduxjs/toolkit'

import { cookie } from '@headless/utils'

import { slice as cartSlice } from '../cart/slice'

const { actions } = cartSlice

export const setGuestEmail = createAsyncThunk(
  'checkout/setGuestEmail',
  async (payload: any, { getState }) => {
    const { email, guestEmailMutation } = payload
    const { cart }: any = getState()
    const { cartId } = cart

    try {
      const { data } = await guestEmailMutation({
        variables: { cartId, email }
      })
      const cartDetail: any = data?.guestEmail?.cart ?? null
      return cartDetail
    } catch (error: any) {
      return null
    }
  }
)

export const applyCouponCode = createAsyncThunk(
  'checkout/applyCouponCode',
  async (payload: any, { dispatch, getState }) => {
    const { promo, applyCouponMutaion } = payload
    const { cart }: any = getState()
    const { cartId } = cart

    const { data, errors } = await applyCouponMutaion({
      variables: { cartId, promo }
    })
    const cartDetail: any = data?.applyCoupon?.cart ?? null
    await dispatch(actions.setCartDetail(cartDetail))
    return { cartDetail, errors }
  }
)

export const cancelCouponCode = createAsyncThunk(
  'checkout/cancelCouponCode',
  async (payload: any, { dispatch, getState }) => {
    const { cancelCouponMutaion } = payload
    const { cart }: any = getState()
    const { cartId } = cart

    try {
      const { data } = await cancelCouponMutaion({
        variables: { cartId }
      })
      const cartDetail: any = data?.cancelCoupon?.cart ?? null
      await dispatch(actions.setCartDetail(cartDetail))
      return cartDetail
    } catch (error: any) {
      return null
    }
  }
)

export const setShippingAddress = createAsyncThunk(
  'checkout/setShippingAddress',
  async (payload: any, { getState }) => {
    const { addresses, shippingAddressMutation } = payload
    const { cart }: any = getState()
    const { cartId } = cart
    const cookieId = cookie.getItem('cart_id')
    const cart_id = cookieId || cartId

    try {
      const { data } = await shippingAddressMutation({
        variables: { cartId: cart_id, addresses }
      })
      const cartDetail: any = data?.shippingAddress?.cart ?? null
      return cartDetail
    } catch (error: any) {
      return null
    }
  }
)

export const setCheckoutBilling = createAsyncThunk(
  'checkout/setCheckoutBilling',
  async (payload: any, { getState }) => {
    const { billingAddressMutation } = payload
    const { cart, checkout }: any = getState()
    const { cartId } = cart
    const { shippingAddress } = checkout
    const { __typename, country, region, ...rest } = shippingAddress

    const addresses: any = {
      address: {
        ...rest,
        country_code: country.code,
        region:
          region.region_id !== '' && region.region_id > 0 ? '' : region.code,
        region_id:
          region.region_id !== '' && region.region_id > 0 ? region.region_id : 0
      }
    }
    try {
      const { data } = await billingAddressMutation({
        variables: { cartId, addresses }
      })
      const cartDetail: any = data?.billingAddress?.cart ?? null
      return cartDetail
    } catch (error: any) {
      return null
    }
  }
)

export const setBillingAddress = createAsyncThunk(
  'checkout/setBillingAddress',
  async (payload: any, { getState }) => {
    const { addresses, billingAddressMutation } = payload
    const { cart }: any = getState()
    const { cartId } = cart

    try {
      const { data } = await billingAddressMutation({
        variables: { cartId, addresses }
      })
      const cartDetail: any = data?.billingAddress?.cart ?? null
      return cartDetail
    } catch (error: any) {
      return null
    }
  }
)

export const setShippingMethod = createAsyncThunk(
  'checkout/setShippingMethod',
  async (payload: any, { dispatch, getState }) => {
    const { method, shippingMethodMutation } = payload
    const { cart }: any = getState()
    const { cartId } = cart

    try {
      const { data } = await shippingMethodMutation({
        variables: { cartId, method }
      })
      const cartDetail: any = data?.shippingMethods?.cart ?? null
      await dispatch(actions.setCartDetail(cartDetail))
      return cartDetail
    } catch (error: any) {
      return null
    }
  }
)

export const setPaymentMethod = createAsyncThunk(
  'checkout/setPaymentMethod',
  async (payload: any, { getState }) => {
    const { method, paymentMethodMutation } = payload
    const { cart }: any = getState()
    const { cartId } = cart

    try {
      const { data } = await paymentMethodMutation({
        variables: { cartId, method }
      })
      const cartDetail: any = data?.paymentMethod?.cart ?? null
      return cartDetail
    } catch (error: any) {
      return null
    }
  }
)

export const placeOrder = createAsyncThunk(
  'checkout/placeOrder',
  async (payload: any, { getState }) => {
    const { placeOrderMutation } = payload
    const { cart, checkout }: any = getState()
    const { cartId } = cart
    const { checkoutComment } = checkout

    const storage = window.sessionStorage

    const mc_cid = storage.getItem('mc_cid')
    const mc_eid = storage.getItem('mc_eid')
    const mc_lp = storage.getItem('mc_lp')

    const variables: any = {
      cartId,
      comment: checkoutComment
    }

    if (mc_cid && mc_eid) {
      variables.mc_cid = mc_cid
      variables.mc_eid = mc_eid
      variables.mc_lp = mc_lp
      storage.removeItem('mc_cid')
      storage.removeItem('mc_eid')
      storage.removeItem('mc_lp')
    }

    try {
      const { data } = await placeOrderMutation({
        variables
      })
      const order: any = data?.placeOrder?.order ?? null
      return Promise.resolve(order)
    } catch (error: any) {
      return Promise.reject(error)
    }
  }
)
