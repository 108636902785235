import styled from '@emotion/styled'

export const StyledMobileSearch = styled.div`
  .MuiTextField-root {
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    z-index: 30;
    margin-bottom: 0;
    padding: 8px 20px;
    opacity: 0;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s;
    transform-origin: top right;
    background-color: #fff;
    border-top: 1px solid #c1c1c1;
    border-bottom: 1px solid #c1c1c1;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: scaleX(1);
    }
  }

  .MuiInputBase-root {
    &:before,
    &:after {
      border: 0 !important;
    }
  }

  .MuiInput-input {
    &::-webkit-input-placeholder {
      color: #222;
      font-size: 12px;
      font-style: italic;
    }
  }
`

export const StyledSearch = styled.div`
  display: flex;
  align-items: center;

  .MuiInputBase-input {
    padding-left: 0.625rem;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

    &::-webkit-input-placeholder {
      color: #a2a2a2;
      font-size: 0.75rem;
      font-style: italic;
      font-weight: 400;
      padding-left: 0.625rem;
    }
  }

  .MuiInput-underline {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      right: 22px;
      left: unset;
      width: 32px;
      height: 32px;
      display: none;
      z-index: 90;
      background: url('/images/circle.gif') center center;
      background-repeat: no-repeat;
      border-bottom: unset;
      transform: unset;
    }
  }

  &.active {
    .MuiInput-underline {
      &:after {
        display: block;
      }
    }
  }
`

export const StyledQuick = styled.div`
  padding: 1.4375rem 2.5rem;
  border-top: 1px solid #eaeaea;
  box-shadow: 0 2px 8px rgb(56 77 108 / 40%);

  .search__title {
    margin-bottom: 0.625rem;

    > span {
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
    }

    > a {
      display: inline-block;
      margin-left: 0.9375rem;
      padding-left: 1.25rem;
      color: #999;
      font-size: 0.875rem;
      vertical-align: top;
      text-transform: capitalize;
      border-left: 1px solid #999;

      span {
        font-size: 1rem;
        font-weight: 500;
      }

      &:hover {
        color: #dbb19f;
      }
    }
  }
`

export const StyledSearchList = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 1.875rem;
  justify-content: flex-start;

  > li {
    > a {
      display: block;

      img {
        width: 100%;
      }
    }
  }

  .product__name {
    margin: 0.3125rem 0;

    > a {
      color: #52575d;
      font-size: 0.875rem;
      font-weight: 500;
      letter-spacing: 0.01em;
      text-decoration: none;

      &:hover {
        color: #dbb19f;
      }
    }
  }

  .price {
    color: #999;
    font-size: 1rem;
    margin-bottom: 0;
  }
`
