import { useMutation } from '@apollo/client'
import { bindActionCreators } from '@reduxjs/toolkit'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'

import { CREATE_CART_MUTATION } from '@graphql/mutations/createEmptyCart'
import { GET_CUSTOMER_CART } from '@graphql/queries/getCustomerCart'
import { GET_GUEST_CART } from '@graphql/queries/getGuestCart'
import {
  actions as cartActions,
  asyncActions as cartAsyncActions
} from '@store/cart'

import { useAwaitQuery } from '@headless/hooks'

const CartContext = createContext({})

function CartContextProvider(props: any) {
  const { actions, cartState, asyncActions, children } = props

  const cartApi = useMemo(
    () => ({
      ...actions,
      ...asyncActions
    }),
    [actions, asyncActions]
  )

  const contextValue = useMemo(() => [cartState, cartApi], [cartApi, cartState])

  const [fetchCartId] = useMutation(CREATE_CART_MUTATION)
  const fetchGuestCart = useAwaitQuery(GET_GUEST_CART)
  const fetchCustomerCart = useAwaitQuery(GET_CUSTOMER_CART)

  useEffect(() => {
    cartApi.getCartDetails({
      fetchCartId,
      fetchGuestCart,
      fetchCustomerCart
    })
  }, [cartApi, fetchCartId, fetchGuestCart, fetchCustomerCart])

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  )
}

const mapStateToProps = ({ cart }: any) => ({ cartState: cart })

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(cartActions, dispatch),
  asyncActions: bindActionCreators(cartAsyncActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CartContextProvider)

export const useCartContext = () => useContext(CartContext)
