import styled from '@emotion/styled'

export const StyledLinks = styled.div`
  padding: 2.5rem 0;
  border-bottom: 1px #b3b3b3 solid;

  .cms-block {
    max-width: 1200px;
    margin: 0 auto;
  }

  .footer {
    &__services {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__column {
      flex: 0 1 16.666667%;

      > h4 {
        position: relative;
        margin-bottom: 0.35rem;
        color: #212529;
        font-size: 1.125rem;
        text-transform: uppercase;
        letter-spacing: 0.075rem;
      }

      &:last-of-type {
        flex: 0 1 33.333333%;
      }

      .social_links {
        display: flex;
        justify-content: left;
        li {
          margin: 0 20px 0 0 !important;

          a {
            background: url('/images/Socialmedia.svg') no-repeat;
            width: 16px;
            height: 18px;
            display: block;
            color: #615956;
          }

          &:nth-of-type(1) a {
            background-position: 2px -1px;
          }

          &:nth-of-type(2) a {
            width: 18px;
            background-position: -95px 1px;
          }

          &:nth-of-type(3) a {
            background-position: -193px 0;
          }

          &:nth-of-type(4) a {
            background-position: -294px 0;
          }
        }
      }
    }

    &__links {
      > li {
        padding-top: 0.4375rem;

        a {
          color: #615956;
          font-size: 1rem;
          letter-spacing: 0.015rem;
          text-decoration: none;
          transition: all 300ms;

          &:hover {
            color: #212529;
            text-decoration: underline;
          }
        }
      }
    }

    &__social {
      display: grid;
      margin-top: 1.5rem;
      grid-auto-flow: column;
      grid-column-gap: 1.25rem;
      justify-content: flex-start;

      .icon {
        width: 1.0625rem;
        height: 1.125rem;
        display: block;
        background-image: url('/images/social.svg');
        background-repeat: no-repeat;

        &-facebook {
          background-position: 2px -1px;
        }

        &-instagram {
          background-position: -95px 1px;
        }

        &-pinterest {
          background-position: -193px 0;
        }

        &-tiktok {
          background-position: -294px 0;
        }
      }
    }
  }

  @media screen and (min-width: 1470px) {
    .cms-block {
      max-width: 1440px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0.9375rem;
    border-bottom: unset;

    .footer {
      &__column {
        flex: 0 1 100%;
        order: 1;
        overflow: hidden;

        > h4 {
          margin-bottom: 0;
          font-size: 1rem;
          font-weight: 500;
          letter-spacing: unset;

          &:after {
            position: absolute;
            top: 10%;
            right: 5px;
            content: '';
            width: 0.5rem;
            height: 1rem;
            background: url('/images/navicon.png') no-repeat;
            background-size: 100%;
            transform: rotate(90deg);
            transition: all 0.3s;
          }
        }

        &:last-of-type {
          margin-bottom: 0.9375rem;
          flex: 0 1 100%;
          order: 0;
          text-align: center;
        }

        &:not(:last-of-type) {
          padding: 0.9375rem 0;
          border-bottom: 1px solid rgba(34, 34, 34, 0.5);
        }

        &.active {
          > h4 {
            &::after {
              transform: rotate(-90deg);
            }
          }

          .footer__links {
            height: unset;
            opacity: 1;
            visibility: visible;
          }
        }
      }

      &__social {
        justify-content: center;
      }

      &__links {
        height: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
      }
    }
  }
`
