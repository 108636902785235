import { createAsyncThunk } from '@reduxjs/toolkit'

import { slice } from './slice'

const { actions } = slice

export const getUserDetails = createAsyncThunk(
  'user/getUserDetails',
  async (payload: any, { dispatch }) => {
    const { fetchUser } = payload

    try {
      const { data } = await fetchUser()
      const customer = data.customer ?? {}
      return customer
    } catch (error: any) {
      return Promise.reject(error)
    }
  }
)
