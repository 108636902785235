import { useQuery } from '@apollo/client'
import { debounce } from 'lodash'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { GET_CMS_CATEGORIES } from '@graphql/queries/getCmsCategories'

export const useCmsNavigation = () => {
  const isMobile = useSelector((state: any) => state.app.isMobile)
  const storeConfig = useSelector((state: any) => state.app.storeConfig)

  // m端已禁用，所以m端也需要拉取桌面端的数据
  const { data } = useQuery(GET_CMS_CATEGORIES, {
    variables: {
      // code: isMobile ? `${storeConfig.code}_m` : storeConfig.code
      code: storeConfig?.code
    }
  })

  const categories = data?.CmsCategories ?? []

  useEffect(() => {
    const handleScroll = debounce(() => {
      const windowWidth: number = window.innerWidth

      if (windowWidth >= 768) {
        const target: any = document.querySelector('.headless__area')
        if (!target) return false
        const height: number = target.offsetTop + target.clientHeight
        const scrollTop: number =
          document.body.scrollTop || document.documentElement.scrollTop

        if (scrollTop > height) {
          target.classList.add('hidden')
        } else {
          target.classList.remove('hidden')
        }
      }
    }, 50)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return {
    categories,
    isMobile
  }
}
