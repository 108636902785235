import { createSlice, Slice } from '@reduxjs/toolkit'

import {
  addProductsToCart,
  getCartDetails,
  removeCartItem,
  updateCartItem
} from './actions'

export const slice: Slice = createSlice({
  name: 'cart',
  initialState: {
    cartId: null,
    cartDetail: null,
    showMiniCart: false
  },
  reducers: {
    setCartId: (state: any, { payload }) => {
      state.cartId = payload
    },
    setCartDetail: (state: any, { payload }) => {
      state.cartId = payload.id
      state.cartDetail = payload
    },
    setInitialState: (state: any) => {
      state.cartId = null
      state.cartDetail = null
    },
    setShowMiniCart: (state: any, { payload }) => {
      state.showMiniCart = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCartDetails.fulfilled, (state, { payload }) => {
        state.cartId = payload?.id ?? null
        state.cartDetail = payload || null
      })
      .addCase(addProductsToCart.fulfilled, (state, { payload }) => {
        if (payload) {
          state.cartId = payload.id
          state.cartDetail = payload
        }
      })
      .addCase(updateCartItem.fulfilled, (state, { payload }) => {
        if (payload) {
          state.cartId = payload.id
          state.cartDetail = payload
        }
      })
      .addCase(removeCartItem.fulfilled, (state, { payload }) => {
        if (payload) {
          state.cartId = payload.id
          state.cartDetail = payload
        }
      })
  }
})
