import { FC, forwardRef } from 'react'

import Paper, { PaperProps } from '@mui/material/Paper'

const HeadlessPaper: FC<PaperProps> = forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <Paper ref={ref} {...props}>
        {children}
      </Paper>
    )
  }
)

HeadlessPaper.displayName = 'Paper'

export default HeadlessPaper
