const data = [
  {
    content:
      '<a href="https://www.norbujewelry.com/necklaces-nr-en" class="level0"><span>Necklaces</span></a>',
    id: 1
  },
  {
    content:
      '<a href="https://www.norbujewelry.com/earrings-nr-en" class="level0"><span>Earrings</span></a>',
    id: 2
  },
  {
    content:
      '<a href="https://www.norbujewelry.com/bracelets-nr-en" class="level0"><span>Bracelets</span></a>',
    id: 3
  },
  {
    content:
      '<a href="https://www.norbujewelry.com/brooches-nr-en" class="level0"><span>Brooches</span></a>',
    id: 4
  },
  {
    content:
      '<a href="https://www.norbujewelry.com/pendants-nr-en" class="level0"><span>Pendants</span></a>',
    id: 5
  },
  {
    content:
      '<a href="https://www.norbujewelry.com/pearl-rings-nr-en" class="level0"><span>Rings</span></a>',
    id: 6
  },
  {
    content:
      '<a href="https://www.norbujewelry.com/loose-pearl-nr-en" class="level0"><span>Loose Pearls</span></a>',
    id: 7
  }
]

export default data
