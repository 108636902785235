import { CacheProvider } from '@emotion/react'
import { ThemeProvider } from '@mui/material/styles'
import { useRouter } from 'next/router'
import { useSnackbar } from 'notistack'
import { FC, memo, ReactNode, useEffect } from 'react'

import { theme } from '@config/theme'
import { HeadlessContextProvider } from '@headless/provider'
import { CssBaseline, CurrentLocale } from '@headless/ui'

import BackTop from '@components/BackTop'
import CartModal from '@components/CartModal'
import Footer from '@components/Footer'
import GlobalStyled from '@components/GlobalStyled'
import Header from '@components/Header'
import PageLoading from '@components/PageLoading'
import AppHelmet from './AppHelmet'
import { StyleAutoHeightMain, StyledMain } from './styled'

interface AppShellProps {
  children: ReactNode
  cache: any
}

const AppShell: FC<AppShellProps> = ({ children, cache }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.getElementById('jss-server-side')
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles)
    }

    window.snackbar = {
      open: (message: any, options: any = {}) =>
        enqueueSnackbar(message, options),
      close: (key: any) => closeSnackbar(key)
    }
  }, [closeSnackbar, enqueueSnackbar])

  const router = useRouter()

  const isAccountPage =
    router &&
    (router.asPath === '/account/create' ||
      router.asPath === '/account/login' ||
      router.asPath === '/account/forgotpassword')

  const Wrapper = isAccountPage ? StyleAutoHeightMain : StyledMain

  const isTarotCardPage =
    router && router.asPath === '/tarot-cards-inner-personality-test'

  return (
    <HeadlessContextProvider>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <AppHelmet />
          <CssBaseline />
          <CurrentLocale />
          <GlobalStyled />
          {!isTarotCardPage && <Header />}
          <Wrapper>{children}</Wrapper>
          {!isTarotCardPage && <Footer />}
          <BackTop />
          <CartModal />
          <PageLoading />
        </ThemeProvider>
      </CacheProvider>
    </HeadlessContextProvider>
  )
}

export default memo(AppShell)
