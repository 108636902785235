import { FC } from 'react'

import { Link } from '@headless/ui'
import { StyledCopry } from './styled'

const CopyRight: FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledCopry className="copyright">
      <span>© 2009-{currentYear} NORBU, All Rights Reserved. </span>
      <Link href="/terms-of-use" title="Terms of Use">
        Terms of Use
      </Link>
      <Link href="/privacy-policy" title="Privacy Policy">
        Privacy Policy
      </Link>
    </StyledCopry>
  )
}

export default CopyRight
