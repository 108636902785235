import styled from '@emotion/styled'

export const StyledCurrency = styled.div`
  .currency {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 0.2rem;
    cursor: pointer;
  }
`
