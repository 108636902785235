import styled from '@emotion/styled'

export const StyledPageLoading = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
  z-index: 999;
  background: rgb(255, 255, 255, 0.65);

  .indicator {
    animation-direction: alternate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: pulse;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  @keyframes pulse {
    0% {
      filter: grayscale(1);
      transform: scale(0.75) rotate(-540deg);
    }

    100% {
      filter: grayscale(0);
      transform: scale(1) rotate(0deg);
    }
  }
`
