import styled from '@emotion/styled'

export const StyledScrollTop = styled.div`
  position: fixed;
  right: 35px;
  width: 45px;
  height: 45px;
  display: flex;
  z-index: 100;
  justify-content: center;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
  transition: all 0.3s;

  .icon {
    width: 1rem;
    height: 2rem;
    display: block;
    background: url('/images/nav.png') no-repeat;
    background-size: 50%;
    background-position: 0 center;
    transform: rotate(-90deg);
  }

  @media screen and (max-width: 767px) {
    right: 20px;
  }
`
