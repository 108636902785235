import { reducer as appReducer } from './app'
import { reducer as cartReducer } from './cart'
import { reducer as catalogReducer } from './catalog'
import { reducer as checkoutReducer } from './checkout'
import { reducer as i18nReducer } from './i18n'
import { reducer as userReducer } from './user'

const headlessReducer = {
  app: appReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  i18n: i18nReducer,
  user: userReducer,
  catalog: catalogReducer
}

export default headlessReducer
