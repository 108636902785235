import { FC, memo } from 'react'

import {
  Button,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@headless/ui'
import { useMiniCartList } from '@hooks/MiniCart'

import BasePrice from '@components/BasePrice'
import { StyledCartList, StyledCartItem, StyledActions } from './styled'

interface MiniCartListProps {
  cartList: any[]
}

const MiniCartList: FC<MiniCartListProps> = ({ cartList }) => {
  const {
    open,
    loading,
    suffix,
    handleOpen,
    handleClose,
    handleConfirm,
    handleFetchImage
  } = useMiniCartList()

  return (
    <StyledCartList scroll={cartList.length > 3}>
      {cartList.map((item: any) => {
        const { quantity, prices, product } = item
        const options: any[] = item?.configurable_options ?? []
        const thumbnail: any = handleFetchImage(options, product)

        return (
          <StyledCartItem key={item.id}>
            <Link
              className="minicart__img"
              href={`/${product.url_key}${suffix}`}
              title={product.name}
            >
              <picture>
                <img src={thumbnail.url} alt={thumbnail.label} />
              </picture>
            </Link>
            <div className="minicart__content">
              <strong>
                <Link
                  href={`/${product.url_key}${suffix}`}
                  title={product.name}
                >
                  <span dangerouslySetInnerHTML={{ __html: product.name }} />
                </Link>
              </strong>
              {options.map((option: any) => {
                return (
                  <div key={option.id}>
                    <span
                      className="label"
                      dangerouslySetInnerHTML={{
                        __html: `${option.option_label} :`
                      }}
                    />
                    &nbsp;
                    <span
                      className="value"
                      dangerouslySetInnerHTML={{
                        __html: option.value_label
                      }}
                    />
                  </div>
                )
              })}
              <div>
                <span className="label">Price:</span>&nbsp;
                <BasePrice className="price" value={prices.price.value} />
              </div>
              <div>
                <span className="label">Qty:</span>&nbsp;
                <span className="value">{quantity}</span>
              </div>
            </div>
            <span
              className="minicart__tools"
              onClick={() => {
                handleOpen(item.id)
              }}
            />
          </StyledCartItem>
        )
      })}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            Are you sure you would like to remove this item from the shopping
            cart?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              variant="contained"
              disabled={loading}
              onClick={handleConfirm}
            >
              Ok
            </Button>
          </StyledActions>
        </DialogActions>
      </Dialog>
    </StyledCartList>
  )
}

export default memo(MiniCartList)
