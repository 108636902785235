import styled from '@emotion/styled'

interface CartList {
  scroll: boolean
}

export const StyledCartList = styled.div<CartList>`
  max-height: 28.125rem;
  overflow-y: ${(props: any) => (props.scroll ? 'scroll' : 'unset')};
`

export const StyledCartItem = styled.div`
  display: grid;
  padding: 0.9375rem 0.5rem 0.9375rem;
  grid-template-columns: 5.625rem 1fr 1rem;
  grid-column-gap: 1rem;
  border-bottom: 1px solid #eaeaea;
  align-items: flex-start;

  .minicart {
    &__img {
      display: block;
      width: 5.625rem;
      height: 7.5rem;

      img {
        width: 100%;
      }
    }

    &__content {
      position: relative;

      a {
        color: #000;
        font-family: 'serif';
        font-size: 1rem;
        font-weight: 500;
        text-decoration: none;

        &:hover {
          color: #dbb19f;
        }
      }

      .label {
        font-size: 0.8125rem;
        font-weight: 500;
      }

      .value,
      .price {
        color: #969696;
        font-size: 0.8125rem;
        font-weight: 500;
      }

      > div {
        display: flex;
        margin-bottom: 0.05rem;
        justify-content: flex-start;
        align-items: center;

        > p {
          margin-bottom: 0;
        }
      }
    }

    &__tools {
      display: block;
      margin-top: 0.2rem;
      width: 1.25rem;
      height: 1.25rem;
      z-index: 30;
      cursor: pointer;
      background: url('/images/gather.svg') no-repeat;
      background-size: 600%;
      background-position: -43px -95px;
    }
  }
`

export const StyledActions = styled.div`
  display: grid;
  padding-bottom: 1rem;
  flex: 1;
  grid-auto-flow: column;
  grid-column-gap: 0.75rem;
  justify-content: center;

  .MuiButton-root {
    padding: 0.25rem 1.25rem;
    color: #222;
    font-size: 0.75rem;
    font-weight: 500;
    border-radius: 0;
    border: 1px solid #222;
    background-color: #fff;

    &:hover {
      background-color: #f2e0d6;
      border-color: #f2e0d6;
    }
  }

  .MuiButton-contained {
    background-color: #f2e0d6;
    border-color: #f2e0d6;

    &:hover {
      background-color: #fff;
      border-color: #222;
    }
  }
`
