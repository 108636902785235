import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'

import reducer from '@store/index'

const __REACT_REDUX_STORE__ = '__REACT_REDUX_STORE__'
const isLogger = process.env.REACT_APP_REDUX_LOGGER === '1'
const isProd = process.env.NODE_ENV === 'production'

const initializeStore = (preloadedState: any) => {
  const middleware: any = []
  const logger = createLogger({
    collapsed: false
  })

  if (isLogger) middleware.push(logger)

  return configureStore({
    reducer: combineReducers(reducer),
    devTools: !isProd,
    preloadedState,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      ...middleware
    ]
  })
}

export const initRedux = (reduxState?: any) => {
  // Always make a new store if server, otherwise state is shared between requests
  if (typeof window === 'undefined') {
    return initializeStore(reduxState)
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__REACT_REDUX_STORE__]) {
    window[__REACT_REDUX_STORE__] = initializeStore(reduxState)
  }

  return window[__REACT_REDUX_STORE__]
}
