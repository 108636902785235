import { clsx } from 'clsx'
import { memo } from 'react'
import { User } from 'react-feather'

import { Button, Drawer, Icon, Link, MenuItem, MenuList } from '@headless/ui'
import { useAccountEntry } from '@hooks/AccountEntry'

import { StyledDrawer, StyledEntry, StyledMenu } from './styled'

const AccountEntry = () => {
  const {
    anchorEl,
    customerName,
    menuLinks,
    isMobile,
    isLogin,
    handleClick,
    handleClose,
    handleLogout
  } = useAccountEntry()

  const MenuLink = () => {
    return (
      <>
        {menuLinks.map((menu: any) => {
          return (
            <MenuItem
              key={menu.url}
              onClick={handleClose}
              onMouseLeave={handleClose}
            >
              {menu.url === '#' ? (
                <span className="actions" onClick={handleLogout}>
                  {menu.title}
                </span>
              ) : (
                <Link href={menu.url} title={menu.title}>
                  <span>{menu.title}</span>
                </Link>
              )}
            </MenuItem>
          )
        })}
      </>
    )
  }

  return (
    <div onMouseEnter={handleClick}>
      <StyledEntry>
        <Icon src={User} />
        {!isMobile && <>{customerName && <span>Hi, {customerName}</span>}</>}
      </StyledEntry>
      {isMobile ? (
        <Drawer
          anchor="right"
          open={Boolean(anchorEl)}
          PaperProps={{
            sx: {
              width: '100%'
            }
          }}
          onClose={handleClose}
        >
          <StyledDrawer>
            <MenuList>
              <MenuLink />
            </MenuList>
            <Button
              variant="contained"
              size="large"
              fullWidth
              className={clsx({
                bottom: isLogin
              })}
              onClick={handleClose}
            >
              Back
            </Button>
          </StyledDrawer>
        </Drawer>
      ) : (
        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          onClose={handleClose}
        >
          <MenuLink />
        </StyledMenu>
      )}
    </div>
  )
}

export default memo(AccountEntry)
