import { useEffect, useMemo } from 'react'
import Router, { useRouter } from 'next/router'

export const useFooter = () => {
  const router = useRouter()

  const isCheckout: boolean = useMemo(() => {
    return router?.route === '/checkout'
  }, [router])

  useEffect(() => {
    const handleRouteComplete = () => {
      const targetEle: any = document.querySelectorAll('.footer__column')

      for (let i = 0; i < targetEle.length; i++) {
        targetEle[i].classList.remove('active')
      }
    }

    Router.events.on('routeChangeComplete', handleRouteComplete)

    return () => {
      Router.events.off('routeChangeComplete', handleRouteComplete)
    }
  }, [])

  return {
    isCheckout
  }
}
