import styled from '@emotion/styled'

interface StyledProps {
  isCheckout: boolean
}

export const StyledFooter = styled.footer<StyledProps>`
  position: relative;
  padding-top: 1rem;
  z-index: 20;
  background-color: ${(props: any) => (props.isCheckout ? '#fff' : '#f2e0d6')};
  border-top: ${(props: any) =>
    props.isCheckout ? '1px solid #eaeaea' : 'unset'};

  @media screen and (max-width: 767px) {
    display: ${(props: any) => (props.isCheckout ? 'none' : 'block')};
  }
`

export const StyledCopyRight = styled.div<StyledProps>`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${(props: any) => (props.isCheckout ? '1rem 0' : '2rem 0')};
  flex-direction: ${(props: any) => (props.isCheckout ? 'column' : 'row')};
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .copyright {
    a,
    span {
      font-size: ${(props: any) => (props.isCheckout ? '0.9rem' : '0.75rem')};
    }
  }

  picture {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: ${(props: any) => (props.isCheckout ? '0.5rem' : '0')};
  }

  @media screen and (min-width: 1470px) {
    max-width: 1440px;
  }

  @media screen and (max-width: 767px) {
    text-align: center;

    picture {
      margin-top: 0.5rem;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 1.5rem 0;

    picture {
      padding: 0 0.75rem;
      justify-content: center;

      img {
        display: block;

        &:last-of-type {
          margin-top: 0.75rem;
        }
      }
    }
  }
`
