import { isEmpty } from 'lodash'

import { Link } from '@headless/ui'
import { useLogo } from '@hooks/Logo'

interface LogoData {
  logo: LogoProps
}

interface LogoProps {
  /** eslint-disable-next-line */
  width?: number
  height?: number
  src?: string
  alt?: string
}

const Logo = (props: LogoProps) => {
  const { logo }: LogoData = useLogo(props)

  const { alt, width, height, src = '' } = logo

  return (
    <Link href="/" title={alt}>
      {isEmpty(logo) ? (
        <span>Logo</span>
      ) : (
        <picture>
          <img src={src} width={width} height={height} alt={alt} />
        </picture>
      )}
    </Link>
  )
}

export default Logo
